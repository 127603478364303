<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#TreemapChart"
      ref="openModalTreemapExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="TreemapChart">
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header flex-row justify-content-between">
            <h5 class="modal-title">Treemap</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mr-0 p-3 pt-0 d-flex justify-content-center">
            <!-- Adding the iframe to load the treemap chart -->
            <iframe
              ref="treemapIframe"
              :srcdoc="treemapChart"
              class="treemap-iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreemapExpandComponent",
  props: {
    treemapChart: {
      type: String,
      required: true, // Accept the chart data passed from parent
    },
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalTreemapExpand;
      if (elem) {
        // Force iframe reload/redraw to ensure proper rendering
        this.$nextTick(() => {
          this.$refs.treemapIframe.srcdoc = this.treemapChart;
        });
        elem.click();
      }
    },
  },
};
</script>

<style scoped>
.treemap-iframe {
  width: 100%;
  height: 80vh;
  border: none;
  overflow: hidden;
}

.modal-body {
  padding: 0 !important;
  margin: 0 !important;
  height: 80vh;
  width: 100%;
}

.modal-content {
  height: calc(80vh + 56px); /* 80vh for iframe + header height */
}

.modal-dialog {
  width: 63vw;
  max-width: 63vw !important;
  margin: 1.75rem auto;
}

.modal-header {
  padding: 1rem;
  height: 56px;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 95vw;
    max-width: 95vw !important;
  }
  
  .treemap-iframe {
    height: 70vh;
  }
  
  .modal-body {
    height: 70vh;
  }
  
  .modal-content {
    height: calc(70vh + 56px);
  }
}
</style>
