// src/services/toastService.js
import { useToast } from "vue-toastification";

const toast = useToast();

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: true,
    icon: true,
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: true,
    icon: true,
  });
};

export const showInfoToast = (message) => {
  toast.info(message, {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: true,
    icon: true,
  });
};
