<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ErrorRole"
      ref="openModalErrorRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="ErrorRole">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <img src="../../assets/images/modal/delete.png" alt="eroor" />
            <p>{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoleErrorModalComponent",
  data() {
    return {
      message: "",
    };
  },
  methods: {
    openModal(mess) {
      this.message = mess;
      var elem = this.$refs.openModalErrorRole;
      if (elem) {
        elem.click();
      }
    },
  },
};
</script>
