<template>
  <div>
    <DataComparisonModalComponent ref="comparisonModal" 
    @sample-updated="fetchNewSampleData"
    :location="sampleData?.location" :sampleData="sampleData" />
    <AIWorkFlowModalComponent 
      ref="aiWorkFlowModal"
      :sampleData="sampleData"
    />
    <div class="table-top-right mt-0">
      <ToggleData v-if="isTable" @toggle-change="onToggleChange" :sampleData="sampleData"/>
      <div v-if="isChart">
        <div class="toggle-select-wrapper">
          <div class="toggle-container">
            <span class="toggle-label">Mole%</span>
            <label class="toggle">
              <input type="checkbox" v-model="switchMole" />
              <span class="slider round"></span>
            </label>
            <span class="toggle-label">wt%</span>
          </div>
          <div class="toggle-container">
            <span class="toggle-label">Linear</span>
            <label class="toggle">
              <input type="checkbox" v-model="yAxisScale" />
              <span class="slider round"></span>
            </label>
            <span class="toggle-label">Logarithmic</span>
          </div>
        </div>
      </div>
      <button 
        type="button" 
        class="btn" 
        @click="callModal()" 
        :disabled="this.sampleData?.type =='Separator Gas' || isPermissionLoading || !hasPermission('ViewSamples')"
      >
        <img :src="ai_corrected_workflow" alt="ai_corrected_workflow" />
        <span>AI Corrected Workflow</span>
      </button>
      <button 
        v-if="isChart && !isPermissionLoading && hasPermission('ViewSamples')" 
        type="button" 
        class="btn" 
        @click="showTableComponent"
      >
        <img :src="show_data" alt="show_data" />
        <span>Show Data</span>
      </button>
      <button 
        type="button" 
        class="btn" 
        :disabled="!this.sampleData?.isCorrected" 
        v-if="isTable" 
        @click="showModal"
      >
        <img :src="show_comparison" alt="show_data" />
        <span>Show Comparison</span>
      </button>
      <button 
        type="button" 
        class="btn" 
        v-if="isTable && !isPermissionLoading && hasPermission('ViewSamples')" 
        @click="showChartComponent"
      >
        <img :src="show_data" alt="show_data" />
        <span>Show Chart</span>
      </button>
      <button 
        type="button" 
        class="btn" 
        @click="exportData" 
        :disabled="exporting || isPermissionLoading || !hasPermission('ViewSamples')"
      >
        <img :src="export_data" alt="export" />
        <span>{{ exporting ? 'Exporting...' : 'Export' }}</span>
      </button>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import DataComparisonModalComponent from "./ComparisonData/DataComparisonModalComponent.vue";
import AIWorkFlowModalComponent from './AIWorkflow/AIWorkFlowModalComponent.vue';
import ToggleData from "./ShowData/ToggleDataComponent.vue";
import * as XLSX from 'xlsx';

export default {
  name: "ChartDataTopRightComponent",
  components: { DataComparisonModalComponent, AIWorkFlowModalComponent,ToggleData },
  props: {
    isCorrected: Boolean,
    view: String,
    location: Object,
    sampleData: Object,
  },
  data() {
    return {
      yAxisScale: true,
      switchMole: true,
      ai_corrected_workflow: require("../../assets/images/ViewSummary/illustrator.png"),
      show_data: require("../../assets/images/ViewSummary/grid-4.png"),
      show_comparison: require("../../assets/images/table/comparison.png"),
      export_data: require("../../assets/images/table/export.png"),
      exporting: false,
    };
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission']),
    isChart() {
      return this.view === "chart";
    },
    isTable() {
      return this.view === "table";
    },
    sampleTypeColumns() {
      const columnsMap = {
        'Physically Recombined': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'Overall_mol', 'Overall_wt'],
        'Bottom Hole': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'Overall_mol', 'Overall_wt'],
        'Mathematically Recombined': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'SeparatorLiquid_mol', 'SeparatorLiquid_wt', 'SeparatorGas_mol', 'SeparatorGas_wt', 'Overall_mol', 'Overall_wt'],
        'Separator Liquid': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'SeparatorLiquid_mol', 'SeparatorLiquid_wt', 'Overall_mol_ST', 'Overall_wt_ST'],
        'Separator Gas': ['SeparatorGas_mol', 'SeparatorGas_wt'],
        'Dead Oil': ['FlashedLiquid_mol_dead', 'FlashedLiquid_wt_dead'],
        'Wellhead': ['FlashedGas_mol', 'FlashedGas_wt', 'FlashedLiquid_mol', 'FlashedLiquid_wt', 'Overall_mol', 'Overall_wt']
      };

      return columnsMap[this.sampleData?.type] || [];
    }
  },
  methods: {
    fetchNewSampleData(newSampleId) {
      this.$emit("sample-updated", newSampleId);
    },
    showTableComponent() {
      this.$emit("viewChanged", "table");
    },
    showChartComponent() {
      this.$emit("viewChanged", "chart");
    },
    showModal() {
      this.$refs.comparisonModal.openModal();
    },
    callModal() {
      this.$refs.aiWorkFlowModal.openModal();
    },
    async exportData() {
      this.exporting = true;
      try {
        const data = this.prepareExcelData(this.sampleData);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sample Data");
        XLSX.writeFile(wb, `${this.sampleData.name}.xlsx`);
      } finally {
        this.exporting = false;
      }
    },
    prepareExcelData(data) {
      const columnMappings = {
        'FlashedGas_mol': ['stockTankGas_MoleFraction', 'Flashed Gas (mol%)'],
        'FlashedGas_wt': ['stockTankGas_MassFraction', 'Flashed Gas (wt%)'],
        'FlashedLiquid_mol': ['stockTankOil_MoleFraction', 'Flashed Liquid (mol%)'],
        'FlashedLiquid_wt': ['stockTankOil_MassFraction', 'Flashed Liquid (wt%)'],
        'SeparatorGas_mol': ['separatorGas_MoleFraction', 'Separator Gas (mol%)'],
        'SeparatorGas_wt': ['separatorGas_MassFraction', 'Separator Gas (wt%)'],
        'SeparatorLiquid_mol': ['separatorLiquid_MoleFraction', 'Separator Liquid (mol%)'],
        'SeparatorLiquid_wt': ['separatorLiquid_MassFraction', 'Separator Liquid (wt%)'],
        'Overall_mol': ['reservoirLiquid_MoleFraction', 'Overall (mol%)'],
        'Overall_wt': ['reservoirLiquid_MassFraction', 'Overall (wt%)'],
        'FlashedLiquid_mol_dead': ['stockTankOil_MoleFraction', 'Dead Oil (mol%)'],
        'FlashedLiquid_wt_dead': ['stockTankOil_MassFraction', 'Dead Oil (wt%)'],
        'Overall_mol_ST': ['separatorLiquid_MoleFraction', 'Overall (mol%)'],
        'Overall_wt_ST': ['separatorLiquid_MassFraction', 'Overall (wt%)']
      };

      const composition = this.useCorrectedData ? data.correctedComposition.composition : data.originalComposition.composition;
      
      const excelData = composition.map(item => {
        const row = {
          "Component": item.component,
          "MW (g/mol)": (item.mw || 0).toFixed(1),
          "Density (g/cc)": (item.density || 0).toFixed(3)
        };

        this.sampleTypeColumns.forEach(column => {
          if (columnMappings[column]) {
            const value = item[columnMappings[column][0]] || 0;
            row[columnMappings[column][1]] = value === 100 ? '100.000' : value.toFixed(3);
          }
        });

        return row;
      });

      // Calculate and add totals row
      const totalsRow = {
        "Component": "Total",
        "MW (g/mol)": "",
        "Density (g/cc)": ""
      };

      this.sampleTypeColumns.forEach(column => {
        if (columnMappings[column]) {
          const sum = excelData.reduce((acc, row) => acc + parseFloat(row[columnMappings[column][1]] || 0), 0);
          totalsRow[columnMappings[column][1]] = sum === 100 ? '100.000' : sum.toFixed(3);
        }
      });

      excelData.push(totalsRow);

      return excelData;
    },
    handleToggleChange(isChecked) {
      this.isCorrected = isChecked;
    },
    onToggleChange(newVal) {
      this.$emit("onToggleChange", newVal);
    },
  },

  watch: {
    switchMole(newVal) {
      this.$emit("switchMoleUpdate", newVal);
    },
    yAxisScale(newVal) {
      this.$emit("yAxisScaleUpdate", newVal);
    },
  }
};
</script>
