<template>
  <div>
    <TreemapExpandComponent ref="treeChartExpand" :treemapChart="treemapChart" />
    <div class="dashboard-chart-selection my-3">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12">
            <label>Select a Treemap</label>
            <vue3-select
              v-model="property"
              :options="options"
              label="value"
              :reduce="option => option"
              @update:modelValue="handlePropertyChange"
              :close-on-select="true"
              placeholder="Select Options"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-charts tree-map-chart">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-xl-12 chart-container">
            <h5 class="text-center error-message d-flex align-items-center justify-content-center" style="height: 755px" v-if="chartError">{{ chartError }}</h5>
            <div class="dashboard-charts-shimmer" v-if="loading">
              <free-style-shimmer
                
                :is-loading="true"
                height="774px"
                width="100%"
                border-radius="12px"
                color="#F6F6F6"
              />
            </div>
            <div v-else-if="!loading && !chartError">
              <div class="chart-btns">
                <button type="button" class="btn export-btn csv-btn" @click="exportCSV">
                  <img :src="export_data" alt="export" />
                  <span>Download CSV</span>
                </button>
                <button type="button" class="btn export-btn" @click="showModalTreeMap">
                  <img :src="expand" alt="expand" />
                  <span>Expand</span>
                </button>
              </div>
              <iframe
                :srcdoc="treemapChart"
                style="width: 100%; height: 767px; border: 0; overflow: hidden;"
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreemapExpandComponent from "@/components/Common/TreemapExpandComponent.vue";
import axios from "axios";

export default {
  name: "DashboardTreeMapComponent",
  components: { TreemapExpandComponent },
  props: {
    filterData: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      expand: require("../../../assets/images/table/expand.png"),
      export_data: require("../../../assets/images/table/export.png"),
      loading: true,
      treemapChart: "",
      chartError: null,
      property: null,
      chartDataExport: "",
      chartWidth:"",
    };
  },
  methods: {
    setChartWidth() {
      const chartContainer = this.$el.querySelector(".dashboard-charts");
      if (chartContainer) {
        this.chartWidth = chartContainer.clientWidth - 20;
      }
    },
    showModalTreeMap() {
      this.$refs.treeChartExpand.openModal();
    },
    handlePropertyChange() {
      // Save selected property to localStorage
      if (this.property) {
        localStorage.setItem('selectedTreemapProperty', JSON.stringify(this.property));
      }
      this.fetchtreemapChart();
    },
    async fetchtreemapChart() {
      if (!this.property) return;

      this.loading = true;
      this.chartError = null;

      this.setChartWidth();

      try {
        const url = `/dataapi/dashboard/treemap?property="${encodeURIComponent(this.property?.name)}"&field="${encodeURIComponent(this.filterData?.field ? this.filterData.field.join(',') : "")}"&asset="${encodeURIComponent(this.filterData?.asset ? this.filterData.asset.join(',') : "")}"&reservoir="${encodeURIComponent(this.filterData?.reservoir ? this.filterData.reservoir.join(',') : "")}"&plot_title="${encodeURIComponent(this.property?.value)}"&width=${this.chartWidth}&height=780`;

        const response = await axios.post(url);

        if (response.data && response.data.data) {
          this.treemapChart = response.data.data.response.html;
          this.chartDataExport = response.data.data.response.chartData;
        } else {
          this.chartError = "Data not found.";
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
        this.chartError = "Error fetching chart data.";
        this.treemapChart = "";
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      if (!this.chartDataExport || this.chartDataExport.length === 0) {
        console.error("No data available for CSV export");
        return;
      }

      // Extract dynamic headers and format them for readability
      const headers = Object.keys(this.chartDataExport[0]).map(header => {
        // Convert camelCase to Title Case (e.g., propertyValue -> Property Value)
        return header.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, str => str.toUpperCase());
      });

      // Map original keys to formatted headers for the CSV
      const originalHeaders = Object.keys(this.chartDataExport[0]);

      // Format the CSV content
      const csvRows = [headers.join(",")]; // Add formatted headers as the first row

      this.chartDataExport.forEach(row => {
        const csvRow = originalHeaders.map(header => {
          const value = row[header];
          // Format numeric values to 3 decimal places, padding with zeros if necessary
          return typeof value === "number" ? Number(value).toFixed(3) : value;
        });
        csvRows.push(csvRow.join(","));
      });

      const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
      const encodedUri = encodeURI(csvContent);

      // Create a download link and trigger it
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "treemap_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  watch: {
    filterData: {
      deep: true,
      handler() {
        this.fetchtreemapChart();
      },
    },
    options: {
      handler() {
        // Check localStorage for saved selection
        const savedProperty = localStorage.getItem('selectedTreemapProperty');
        if (savedProperty && this.options.length > 0) {
          const parsedProperty = JSON.parse(savedProperty);
          // Find matching option
          const matchingOption = this.options.find(opt => 
            opt.name === parsedProperty.name && opt.value === parsedProperty.value
          );
          this.property = matchingOption || this.options[0];
        } else {
          this.property = this.options[0];
        }
        this.fetchtreemapChart();
      },
    },
  },
  async mounted() {
    // Check localStorage for saved selection on component mount
    const savedProperty = localStorage.getItem('selectedTreemapProperty');
    if (savedProperty && this.options?.length > 0) {
      const parsedProperty = JSON.parse(savedProperty);
      const matchingOption = this.options.find(opt => 
        opt.name === parsedProperty.name && opt.value === parsedProperty.value
      );
      this.property = matchingOption || this.options[0];
    }
    this.fetchtreemapChart();
    window.addEventListener("resize", this.setChartWidth);
  },
  beforeUnmount() {
    // Clean up resize listener
    window.removeEventListener("resize", this.setChartWidth);
  }
};
</script>


<style></style>