<template>
  <div class="chart-data-bottom">
    <!-- Flashed Gas -->
    <div v-if="checkboxVisibility.flashed_gas" class="form-check d-flex align-items-center">
      <input
        class="form-check-input mt-0 custom-checkbox"
        type="checkbox"
        id="flashedGas"
        @change="updateSampleData"
        v-model="filterData.flashed_gas"
      />
      <label class="form-check-label flashedGas" for="flashedGas"> Flashed Gas </label>
      <span class="marker" :class="shapeMapCorrected.flashed_gas"></span>
    </div>

    <!-- Flashed Liquid (Corrected and Uncorrected) -->
    <div v-if="(chartType === 'all' || chartType === 'sto') && (checkboxVisibility.sto_corrected_composition || checkboxVisibility.sto_uncorrected_composition)" class="form-group">
      <h6>Flashed Liquid</h6>
      <div v-if="checkboxVisibility.sto_corrected_composition" class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="stoCorrectedComposition"
          @change="updateSampleData"
          v-model="filterData.sto_corrected_composition"
        />
        <label class="form-check-label stoCorrectedComposition" for="stoCorrectedComposition"> Corrected Composition </label>
        <span class="marker" :class="shapeMapCorrected.sto_corrected_composition"></span>
      </div>
      <div v-if="checkboxVisibility.sto_uncorrected_composition" class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="stoUncorrectedComposition"
          @change="updateSampleData"
          v-model="filterData.sto_uncorrected_composition"
        />
        <label class="form-check-label stoUncorrectedComposition" for="stoUncorrectedComposition"> Uncorrected Composition </label>
        <span class="marker" :class="shapeMapUncorrected.sto_uncorrected_composition"></span>
      </div>
    </div>

    <!-- Separator Liquid (Corrected and Uncorrected) -->
    <div v-if="chartType === 'all' && (checkboxVisibility.separator_liquid_corrected || checkboxVisibility.separator_liquid_uncorrected)" class="form-group">
      <h6>Separator Liquid</h6>
      <div v-if="checkboxVisibility.separator_liquid_corrected" class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="separatorLiquidCorrected"
          @change="updateSampleData"
          v-model="filterData.separator_liquid_corrected"
        />
        <label class="form-check-label separatorLiquidCorrected" for="separatorLiquidCorrected"> Corrected Composition </label>
        <span class="marker" :class="shapeMapCorrected.separator_liquid_corrected"></span>
      </div>
      <div v-if="checkboxVisibility.separator_liquid_uncorrected" class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="separatorLiquidUncorrected"
          @change="updateSampleData"
          v-model="filterData.separator_liquid_uncorrected"
        />
        <label class="form-check-label separatorLiquidUncorrected" for="separatorLiquidUncorrected"> Uncorrected Composition </label>
        <span class="marker" :class="shapeMapUncorrected.separator_liquid_uncorrected"></span>
      </div>
    </div>

    <!-- Separator Gas -->
    <div v-if="checkboxVisibility.separator_gas" class="form-check d-flex align-items-center">
      <input
        class="form-check-input mt-0 custom-checkbox"
        type="checkbox"
        id="separatorGas"
        v-model="filterData.separator_gas"
        @change="updateSampleData"
      />
      <label class="form-check-label separatorGas" for="separatorGas"> Separator Gas </label>
      <span class="marker" :class="shapeMapCorrected.separator_gas"></span>
    </div>

    <!-- Reservoir Fluid (Corrected and Uncorrected) -->
    <div v-if="chartType === 'all' && (checkboxVisibility.reservoir_fluid_corrected || checkboxVisibility.reservoir_fluid_uncorrected)" class="form-group">
      <h6>Reservoir Fluid</h6>
      <div v-if="checkboxVisibility.reservoir_fluid_corrected" class="form-check d-flex align-items-center">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="reservoirFluidCorrected"
          @change="updateSampleData"
          v-model="filterData.reservoir_fluid_corrected"
        />
        <label class="form-check-label reservoirFluidCorrected" for="reservoirFluidCorrected"> Corrected Composition </label>
        <span class="marker" :class="shapeMapCorrected.reservoir_fluid_corrected"></span>
      </div>
      <div v-if="checkboxVisibility.reservoir_fluid_uncorrected" class="form-check d-flex align-items-center mt-2">
        <input
          class="form-check-input mt-0 custom-checkbox"
          type="checkbox"
          id="reservoirFluidUncorrected"
          @change="updateSampleData"
          v-model="filterData.reservoir_fluid_uncorrected"
        />
        <label class="form-check-label reservoirFluidUncorrected" for="reservoirFluidUncorrected"> Uncorrected Composition </label>
        <span class="marker" :class="shapeMapUncorrected.reservoir_fluid_uncorrected"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SampleChartBottomComponent",
  data() {
    return {
      filterData: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      },
      checkboxVisibility: {},
      shapeMapCorrected: {
        flashed_gas: "shape-circle-solid",
        sto_corrected_composition: "shape-square-solid",
        separator_liquid_corrected: "shape-triangle-up-solid",
        separator_gas: "shape-diamond-solid",
        reservoir_fluid_corrected: "shape-cross-solid",
      },
      shapeMapUncorrected: {
        flashed_gas: "shape-circle-outline",
        sto_uncorrected_composition: "shape-square-outline",
        separator_liquid_uncorrected: "shape-triangle-up-outline",
        separator_gas: "shape-diamond-outline",
        reservoir_fluid_uncorrected: "shape-cross-outline",
      },
    };
  },
  props: {
    chartType: String,
    showSelection: Boolean,
    switchMole: Boolean,
    sampleData: Object,
  },
  methods: {
    updateSampleData() {
      this.$emit("updateDataOnCheck", this.filterData);
    },
  },
  watch: {
    switchMole: {
      immediate: true,
      handler(switchMole) {
        const glossary = {
          flashed_gas: switchMole ? 'stockTankGas_MoleFraction' : 'stockTankGas_MassFraction',
          sto_corrected_composition: switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction',
          sto_uncorrected_composition: switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction',
          separator_liquid_corrected: switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction',
          separator_liquid_uncorrected: switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction',
          separator_gas: switchMole ? 'separatorGas_MoleFraction' : 'separatorGas_MassFraction',
          reservoir_fluid_corrected: switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction',
          reservoir_fluid_uncorrected: switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction',
        };

        this.checkboxVisibility = {};
        this.filterData = {};

        for (const [key, property] of Object.entries(glossary)) {
          const correctedExists = this.sampleData.correctedComposition?.composition.some(
            (item) => item.hasOwnProperty(property)
          );
          const originalExists = this.sampleData.originalComposition?.composition.some(
            (item) => item.hasOwnProperty(property)
          );

          this.checkboxVisibility[key] = correctedExists || originalExists;
          this.filterData[key] = this.checkboxVisibility[key];
        }
      },
    },
  },
};
</script>

<style scoped>
/* Styling as per your original component */
</style>
