<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#workFlowModal"
      ref="openModalWorkFlow"
    ></button>
    <!-- Modal Structure -->
    <div
      class="modal fade"
      id="workFlowModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="workFlowModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="ai-correction-sec">
              <h6>AI Correction workflow</h6>
              <div class="stepper">
                <div
                  v-for="(step, index) in steps"
                  :key="index"
                  :class="['step', { active: index <= currentStep }]"
                >
                  <div
                    :class="['circle', { active: index <= currentStep }]"
                    @click="setStep(index)"
                  >
                    {{ index + 1 }}
                  </div>
                  <div
                    :class="['label', { active: index <= currentStep }]"
                    @click="setStep(index)"
                  >
                    {{ step.label }}
                  </div>
                  <div v-if="index < steps.length - 1" class="line"></div>
                </div>
              </div>
            </div>
            <div class="ai-step-wrapper">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div :class="stepInfoClass" v-if="currentStepText">
                <div :class="['ai-step-info']" :style="stepInfoStyle">
                  <div class="top">
                    <div
                      :class="[
                        'circle',
                        { active: currentStep <= steps.length - 1 },
                      ]"
                    >
                      {{ currentStep + 1 }}
                    </div>
                    <div class="title-text-wrap">
                      <h6>
                        {{ currentStepText.label }}
                      </h6>
                      <p class="mb-0" v-html="currentStepText.text"></p>
                      <h5 class="error-2" v-if="currentStep === 0">{{ correctionPlotError }}</h5>
                      <h5 class="error-2" v-if="currentStep === 1">{{ histogramPlotError }}</h5>
                      <h5 class="error-2" v-if="currentStep === 3">{{ correctionProbabilityChartError }}</h5>
                    </div>
                  </div>
                </div>

                <div v-if="currentStep === 0" class="chart-container overflow-hidden">
                  <free-style-shimmer
                    v-if="loading_one"
                    :is-loading="true"
                    height="calc(100vh - 300px)"
                    width="100%"
                    border-radius="12px"
                    color="#F6F6F6"
                  />
                  <iframe
                    v-else-if="correctionBarPlot && !loading_one"
                    :srcdoc="correctionBarPlot"
                    style="
                      width: 100%;
                      height: 746px;
                      border: 0;
                      overflow: hidden;
                    "
                    scrolling="no"
                  ></iframe>
                </div>

                <div v-if="currentStep === 1" class="chart-container overflow-hidden mt-2">
                  <free-style-shimmer
                    v-if="loading_two"
                    :is-loading="true"
                    height="calc(100vh - 300px)"
                    width="100%"
                    border-radius="12px"
                    color="#F6F6F6"
                  />
                  <iframe
                    v-if="histogramPlot1 && !loading_two"
                    :srcdoc="histogramPlot1"
                    style="
                      width: 100%;
                      height: 100%;
                      border: 0;
                      overflow: hidden;
                    "
                  ></iframe>
                  <iframe
                    v-if="!loading_two"
                    :srcdoc="histogramPlot2"
                    style="
                      width: 100%;
                      height: 100%;
                      border: 0;
                      overflow: hidden;
                    "
                    scrolling="no"
                  ></iframe>
                  <iframe
                    v-if="!loading_two"
                    :srcdoc="histogramPlot3"
                    style="
                      width: 100%;
                      height: 100%;
                      border: 0;
                      overflow: hidden;
                    "
                    scrolling="no"
                  ></iframe>
                </div>

                <div v-if="currentStep === 2" class="chart-container mt-2">
                  <free-style-shimmer
                    v-if="loading_cluster"
                    :is-loading="true"
                    height="calc(100vh - 300px)"
                    width="100%"
                    border-radius="12px"
                    color="#F6F6F6"
                  />
                  <ClusterDataComponent
                      ref="sampleChartComponent"
                      @start-loading="startClusterLoading"
                      @stop-loading="stopClusterLoading"
                      :filterData="filterData"
                      :sampleData="sampleData"
                    />
              
                </div>

                <div v-if="currentStep === 3" class="chart-container mt-2">
                  <free-style-shimmer
                    v-if="loading_three"
                    :is-loading="true"
                    height="calc(100vh - 300px)"
                    width="100%"
                    border-radius="12px"
                    color="#F6F6F6"
                  />
                  <iframe
                    v-else-if="correctionProbabilityChart && !loading_three"
                    :srcdoc="correctionProbabilityChart"
                    style="
                      width: 100%;
                      height: 100%;
                      border: 0;
                      overflow: hidden;
                    "
                    scrolling="no"
                  ></iframe>
              
                </div>

                
                <div v-if="currentStep === 4" class="table-container mt-2">
                  <div v-html="currentStepText.tableHtml"></div>
                </div>

                <div
                  v-if="(currentStep === 5 || currentStep === 6)"
                  class="chart-container mt-2"
                >
                  <div class="d-xl-flex align-items-center justify-content-between flex-wrap mb-2">
                    <div v-if="currentStep === 5 && hasFlashedLiquidFraction" class="toggle-container" >
                        <span class="toggle-label">Flashed Liquid</span>
                        <div class="form-check d-flex align-items-center" v-if="sampleData.isCorrected">
                          <input
                            class="form-check-input mt-0 custom-checkbox"
                            type="checkbox"
                            id="stoCorrectedComposition"
                            @change="updateStepSixFilter"
                            v-model="filterData.sto_corrected_composition"
                          />
                          <label
                            class="form-check-label stoCorrectedComposition"
                            for="stoCorrectedComposition"
                          >
                            Corrected
                          </label>
                          <span class="marker" :class="shapeMapCorrected.sto_corrected_composition"></span>
                        </div>
                        <div class="form-check d-flex align-items-center">
                          <input
                            class="form-check-input mt-0 custom-checkbox"
                            type="checkbox"
                            id="stoUncorrectedComposition"
                            @change="updateStepSixFilter"
                            v-model="filterData.sto_uncorrected_composition"
                          />
                          <label
                            class="form-check-label stoUncorrectedComposition"
                            for="stoUncorrectedComposition"
                          >
                            Uncorrected
                          </label>
                          <span
                            class="marker"
                            :class="shapeMapUncorrected.sto_uncorrected_composition"
                          ></span>
                        </div>
                    </div>
                    <div v-if="currentStep === 6 && hasSeparatorLiquidFraction" class="toggle-container">
                        <span class="toggle-label">Separator Liquid</span>
                        <div class="form-check d-flex align-items-center" v-if="sampleData.isCorrected">
                          <input
                            class="form-check-input mt-0 custom-checkbox"
                            type="checkbox"
                            id="separatorLiquidCorrected"
                            @change="updateStepSevenFilter"
                            v-model="filterData.separator_liquid_corrected"
                          />
                          <label
                            class="form-check-label separatorLiquidCorrected"
                            for="separatorLiquidCorrected"
                          >
                            Corrected
                          </label>
                          <span class="marker" :class="shapeMapCorrected.separator_liquid_corrected"></span>
                        </div>
                        <div class="form-check d-flex align-items-center">
                          <input
                            class="form-check-input mt-0 custom-checkbox"
                            type="checkbox"
                            id="separatorLiquidUncorrected"
                            @change="updateStepSevenFilter"
                            v-model="filterData.separator_liquid_uncorrected"
                          />
                          <label
                            class="form-check-label separatorLiquidUncorrected"
                            for="separatorLiquidUncorrected"
                          >
                            Uncorrected
                          </label>
                          <span
                            class="marker"
                            :class="shapeMapUncorrected.separator_liquid_uncorrected"
                          ></span>
                        </div>
                      </div>
                    <div v-if="currentStep === 6 && hasReservoirLiquidFraction" class="toggle-container">
                      <span class="toggle-label">Reservoir Fluid</span>
                      <div class="form-check d-flex align-items-center" v-if="sampleData.isCorrected">
                        <input
                          class="form-check-input mt-0 custom-checkbox"
                          type="checkbox"
                          id="reservoirFluidCorrected"
                          @change="updateStepSevenFilter"
                          v-model="filterData.reservoir_fluid_corrected"
                        />
                        <label
                          class="form-check-label reservoirFluidCorrected"
                          for="reservoirFluidCorrected"
                        >
                          Corrected
                        </label>
                        <span class="marker" :class="shapeMapCorrected.reservoir_fluid_corrected"></span>
                      </div>
                      <div class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input mt-0 custom-checkbox"
                          type="checkbox"
                          id="reservoirFluidUncorrected"
                          @change="updateStepSevenFilter"
                          v-model="filterData.reservoir_fluid_uncorrected"
                        />
                        <label
                          class="form-check-label reservoirFluidUncorrected"
                          for="reservoirFluidUncorrected"
                        >
                          Uncorrected
                        </label>
                        <span
                          class="marker"
                          :class="shapeMapUncorrected.reservoir_fluid_uncorrected"
                        ></span>
                      </div>
                    </div>
                    <div class="toggle-select-wrapper">
                      <div class="toggle-container">
                        <span class="toggle-label">Mole%</span>
                        <label class="toggle">
                          <input type="checkbox" v-model="switchMole" />
                          <span class="slider round"></span>
                        </label>
                        <span class="toggle-label">wt%</span>
                      </div>
                      <div class="toggle-container">
                        <span class="toggle-label">Linear</span>
                        <label class="toggle">
                          <input type="checkbox" v-model="yAxisScale" />
                          <span class="slider round"></span>
                        </label>
                        <span class="toggle-label">Logarithmic</span>
                      </div>
                    </div>
                  </div>
                    <ScatterChartForAI
                      :localSampleList="aiLocalSampleData"
                      :originalSampleList="sampleData"
                      :yAxisScale="yAxisScale"
                      :switchMole="switchMole"
                    />
                </div>

                <div v-if="currentStep === 7" class="chart-container mt-2">
                  <free-style-shimmer
                    v-if="loading_cluster"
                    :is-loading="true"
                    height="calc(100vh - 300px)"
                    width="100%"
                    border-radius="12px"
                    color="#F6F6F6"
                  />
                  <ClusterDataComponent
                      ref="sampleChartComponent"
                      :filterData="filterData"
                      :sampleData="sampleData"
                      @start-loading="startClusterLoading"
                      @stop-loading="stopClusterLoading"
                    />
              
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button
                @click="previousStep"
                v-if="currentStep > 0"
                class="btn cancel mr-3"
              >
                <i class="fas fa-chevron-left"></i>
                <span> Previous </span>
              </button>
            </div>
            <div>
              <button
                @click="nextStep"
                :disabled="currentStep >= steps.length - 1"
                class="btn main-btn"
              >
                <span> Next </span>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import TwoDScatterChart from "@/components/Charts/2DScatterChart.vue";
import ThreeDScatterChart from "@/components/Charts/3DScatterChart.vue";
import ScatterChartForAI from "@/components/Charts/ScatterChartForAI.vue";
import ClusterDataComponent from "@/components/Common/ClusterDataComponent.vue";
import TwoDHistogram from "../../Charts/2DHistogram.vue";
import { getFilteredData } from "@/utils/useFilteredData.js";
import { calc } from 'plotly.js-dist';

export default {
  name: "AIWorkFlowModalComponent",
  components: {
    TwoDScatterChart,
    ThreeDScatterChart,
    ScatterChartForAI,
    TwoDHistogram,
    ClusterDataComponent
  },
  data() {
    return {
      loading: true,
      yAxisScale: true,
      switchMole: true,
      loading_one: true,
      loading_two: false,
      loading_three: false,
      loading_cluster: true,
      currentStep: 0,
       apiCalls: {
        step0: false,
        step1: false,
        step2: false,
        step3: false,
      },
      steps: [
        { label: "Qualify fluid for composition correction" },
        { label: "Locate Fluid in the Existing Multidimension Space​" },
        { label: "Uncorrected Fluid Relative to Existing Clusters​" },
        { label: "Calculate AI-ML Model Parameters and Similarity Index" },
        { label: "Correct Plus Fraction Properties with AI-ML Models" },
        { label: "Correct STL Compositions with AI-ML Models" },
        { label: "Correct Live Compositions with AI-ML Models" },
        { label: "Corrected Fluid Relative to Existing Clusters​" },

      ],
      stepTexts: [
        {
          text: "Qualify/Reject the uncorrected STL composition input for Compo++ AI-ML correction",
          label: "Qualify fluid for composition correction",
        },
        {
          text: "Locate Fluid in the Existing Multidimension Space",
          label: "Locate Fluid in the Existing Multidimension Space",
          image: require("@/assets/images/AiCorrection/step-two.png"),
        },
        {
          text: "Uncorrected Fluid Relative to Existing Clusters",
          label: "Uncorrected Fluid Relative to Existing Clusters",
        },
        {
          text: "Calculate weighted distances, AI-ML models parameters for C36+’s wt%, SG & MW correction including similarity Index of AI-ML model for prediction",
          label: "Calculate AI-ML Model Parameters and Similarity Index",
          image: require("@/assets/images/AiCorrection/step-three.png"),
        },
        {
          text: "Correct C36+ wt%, C36+ density, C36+ MW with Compo++ AI-ML models",
          label: "Correct Plus Fraction Properties with AI-ML Models",
          tableHtml: `<table class="table">
                <thead>
                  <tr class="header-row">
                    <th>Parameter Correction</th>
                    <th>Uncorrected</th>
                    <th>Corrected</th>'
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>STL C36+, wt%</td>
                    <td>${(this.sampleData?.originalComposition?.c36plus_MassFraction || 0).toFixed(3)} ${this.sampleData?.originalComposition?.c36plus_MassFraction_UOM || ""}</td>
                    <td>${(this.sampleData?.correctedComposition?.c36plus_MassFraction || 0).toFixed(3)} ${this.sampleData?.correctedComposition?.c36plus_MassFraction_UOM || ""}</td>
                  </tr>
                  <tr>
                    <td>STL C36+ Density, g/cc</td>
                    <td>${(this.sampleData?.originalComposition?.c36plus_Density || 0).toFixed(3)} ${this.sampleData?.originalComposition?.c36plus_Density_UOM || ""}</td>
                    <td>${(this.sampleData?.correctedComposition?.c36plus_Density || 0).toFixed(3)} ${this.sampleData?.correctedComposition?.c36plus_Density_UOM || ""}</td>
                  </tr>
                  <tr>
                    <td>STL C36+MW, g/mol</td>
                    <td>${(this.sampleData?.originalComposition?.c36plus_MW || 0).toFixed(1)} ${this.sampleData?.originalComposition?.c36plus_MW_UOM || ""}</td>
                    <td>${(this.sampleData?.correctedComposition?.c36plus_MW || 0).toFixed(1)} ${this.sampleData?.correctedComposition?.c36plus_MW_UOM || ""}</td>
                  </tr>
                </tbody>
              </table>`
        },
        {
          text: "Calculate and report AI-ML corrected plus fraction properties for STL",
          label: "Correct STL Compositions with AI-ML Models",
          image: require("@/assets/images/AiCorrection/step-six.png"),
        },
        {
          text: "Calculate and report AI-ML corrected plus fraction properties for live oil",
          label: "Correct Live Compositions with AI-ML Models",
          image: require("@/assets/images/AiCorrection/step-six.png"),
        },
        {
          text: "Corrected Fluid Relative to Existing Clusters",
          label: "Corrected Fluid Relative to Existing Clusters"
        },
      ],
      selected_file: null,
      files: [],
      chartType: "all",
      filterData: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      },
      shapeMapCorrected: {
        flashed_gas: "shape-circle-solid",
        sto_corrected_composition: "shape-square-solid",
        separator_liquid_corrected: "shape-triangle-up-solid",
        separator_gas: "shape-diamond-solid",
        reservoir_fluid_corrected: "shape-cross-solid",
      },
      shapeMapUncorrected: {
        flashed_gas: "shape-circle-outline",
        sto_uncorrected_composition: "shape-square-outline",
        separator_liquid_uncorrected: "shape-triangle-up-outline",
        separator_gas: "shape-diamond-outline",
        reservoir_fluid_uncorrected: "shape-cross-outline",
      },
      aiLocalSampleData: null,
      correctionBarPlot: null,
      correctionPlotError: null,
      histogramPlot1: null,
      histogramPlot2: null,
      histogramPlot3: null,
      histogramPlotError: null,
      correctionProbabilityChart: null,
      correctionProbabilityChartError: null,
    };
  },
  props: {
    sampleData: Object,
  },
  computed: {
    currentStepText() {
      return this.stepTexts[this.currentStep] || "";
    },
    stepInfoClass() {
      return {
        "flex-column": this.currentStep < 4,
        "flex-column": this.currentStep > 4,
      };
    },
    stepInfoStyle() {
      // if (this.currentStep === 0) {
      //   return { width: "calc(100% - 800px)" };
      // } else if (this.currentStep === 1 || this.currentStep === 2) {
      //   return { width: "350px" };
      // } else {
      //   return {}; // Default style for other steps
      // }
    },
    hasReservoirLiquidFraction() {
      const checkFraction = (composition) => 
        composition?.some((item) => 
          (item.reservoirLiquid_MoleFraction !== null && item.reservoirLiquid_MoleFraction !== undefined) ||
          (item.reservoirLiquid_MassFraction !== null && item.reservoirLiquid_MassFraction !== undefined)
        );

      const originalHasFraction = checkFraction(this.sampleData?.originalComposition?.composition);
      const correctedHasFraction = checkFraction(this.sampleData?.correctedComposition?.composition);

      return originalHasFraction || correctedHasFraction;
    },

    hasFlashedLiquidFraction() {
      const checkFraction = (composition) => 
        composition?.some((item) => 
          (item.stockTankOil_MoleFraction !== null && item.stockTankOil_MoleFraction !== undefined) ||
          (item.stockTankOil_MassFraction !== null && item.stockTankOil_MassFraction !== undefined)
        );

      const originalHasFraction = checkFraction(this.sampleData?.originalComposition?.composition);
      const correctedHasFraction = checkFraction(this.sampleData?.correctedComposition?.composition);

      return originalHasFraction || correctedHasFraction;
    },

    hasSeparatorLiquidFraction() {
      const checkFraction = (composition) => 
        composition?.some((item) => 
          (item.separatorLiquid_MoleFraction !== null && item.separatorLiquid_MoleFraction !== undefined) ||
          (item.separatorLiquid_MassFraction !== null && item.separatorLiquid_MassFraction !== undefined)
        );

      const originalHasFraction = checkFraction(this.sampleData?.originalComposition?.composition);
      const correctedHasFraction = checkFraction(this.sampleData?.correctedComposition?.composition);

      return originalHasFraction || correctedHasFraction;
    },
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalWorkFlow;
      if (elem) {
        elem.click();
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        this.chartType = this.currentStep === 5 ? "sto" : "all";
        this.chartsFilter();
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.chartType = this.currentStep === 5 ? "sto" : "all";
        this.chartsFilter();
      }
    },

    async callApiForStep(step) {
      switch (step) {
        // case 0:
        //   if (!this.apiCalls.step0) {
        //     await this.drawCorrectionBarPlot();
        //     this.apiCalls.step0 = true;
        //   }
        //   break;
        case 1:
          if (!this.apiCalls.step1) {
            await this.drawHistogramPlot();
            this.apiCalls.step1 = true;
          }
          break;
        case 3:
          if (!this.apiCalls.step3) {
            await this.drawCorrectionProbabilityChart();
            this.apiCalls.step3 = true;
          }
          break;
      }
    },

    setStep(index) {
      this.currentStep = index;
      this.chartType = this.currentStep === 5 ? "sto" : "all";
      this.chartsFilter();
    },

    async chartsFilter() {
      if (this.currentStep === 2) {
        this.filterData= {
              asset: [],
              field: [],
              reservoir:[],
              well: [],
              sample:[],
              sampleType:[],
              fluidType:[],
              isCorrected:false,
              isAnchorPoint:null,
              filterType:null,
        };
      }
      // STO
      if (this.currentStep === 5) {
        this.filterData = {
          flashed_gas: false,
          sto_corrected_composition: true,
          sto_uncorrected_composition: true,
          separator_liquid_corrected: false,
          separator_liquid_uncorrected: false,
          separator_gas: false,
          reservoir_fluid_corrected: false,
          reservoir_fluid_uncorrected: false,
        };
        this.aiLocalSampleData = getFilteredData(
          this.filterData,
          this.sampleData,
          this.sampleData,
          this.switchMole
        );
      }
      if (this.currentStep === 6) {
        this.filterData = {
          flashed_gas: false,
          sto_corrected_composition: false,
          sto_uncorrected_composition: false,
          separator_liquid_corrected: true,
          separator_liquid_uncorrected: true,
          separator_gas: false,
          reservoir_fluid_corrected: true,
          reservoir_fluid_uncorrected: true,
        };
        this.aiLocalSampleData = getFilteredData(
          this.filterData,
          this.sampleData,
          this.sampleData,
          this.switchMole
        );
      }
      if (this.currentStep === 7) {
        this.filterData= {
              asset: [],
              field: [],
              reservoir:[],
              well: [],
              sample:[],
              sampleType:[],
              fluidType:[],
              isCorrected:true,
              isAnchorPoint:null,
              filterType:null,
        };
      }
    },
    startClusterLoading() {
      this.loading_cluster=true;

    },
    stopClusterLoading() {
      this.loading_cluster=false;
    },
    async updateStepSixFilter() {
        this.filterData = {
          flashed_gas: false,
          sto_corrected_composition: this.filterData.sto_corrected_composition,
          sto_uncorrected_composition: this.filterData.sto_uncorrected_composition,
          separator_liquid_corrected: false,
          separator_liquid_uncorrected: false,
          separator_gas: false,
          reservoir_fluid_corrected: false,
          reservoir_fluid_uncorrected: false,
        };
        this.aiLocalSampleData = getFilteredData(
          this.filterData,
          this.sampleData,
          this.sampleData,
          this.switchMole
        );
    },
    async updateStepSevenFilter() {
      this.filterData = {
          flashed_gas: false,
          sto_corrected_composition: false,
          sto_uncorrected_composition: false,
          separator_liquid_corrected: this.filterData.separator_liquid_corrected,
          separator_liquid_uncorrected: this.filterData.separator_liquid_uncorrected,
          separator_gas: false,
          reservoir_fluid_corrected: this.filterData.reservoir_fluid_corrected,
          reservoir_fluid_uncorrected: this.filterData.reservoir_fluid_uncorrected,
        };
        this.aiLocalSampleData = getFilteredData(
          this.filterData,
          this.sampleData,
          this.sampleData,
          this.switchMole
        );
    },
    async drawCorrectionBarPlot() {
      this.correctionBarPlot =null;
      this.correctionPlotError =null;
      if (this.currentStep === 0 && this.sampleData?.id != null) {
        this.loading_one = true;
        try {
          const response = await axios.get(
            `samples/${this.sampleData.id}/correction_bar_plot`
          );
          if (response.data && response.data.data && response.data.statusCode==200) {
            this.correctionBarPlot = response.data.data.response;
          } else {
            this.correctionPlotError =  response.data.message;
          }
        } catch (error) {
          console.error("Error loading correction bar plot", error);
          this.correctionPlotError =error.message;
        } finally {
          this.loading_one = false;
        }
      }
    },
    async drawHistogramPlot() {
      this.histogramPlot1 =null;
      this.histogramPlot2 =null;
      this.histogramPlot3 =null;
      this.histogramPlotError =null;
      if (this.currentStep === 1 && this.sampleData?.id != null) {
        this.loading_two = true;
        const parentDiv = document.querySelector(".ai-step-wrapper"); // Add an ID to the parent div if it doesn't have one
        const parentWidth = 1000;
        const parentHeight = parentDiv ? parentDiv.offsetHeight : 600;
       const graphHeight = 1000;
        try {
          const response = await axios.get(
            `samples/${this.sampleData.id}/correction_2d_histogram_plots`,
            {
              params: {
                plot_width : parentWidth,        
                plot_height : graphHeight,
                bar_1_y : 0.88,
                bar_2_y : 0.515,
                bar_3_y : 0.15,
                bar_length : 0.3,
                v_spacing : 0.1,
              },
            }
          );
          if (response.data && response.data.data && response.data.statusCode==200) {
            this.histogramPlot1 = response.data.data.response;
            // this.histogramPlot2 = response.data.data.response;
            // this.histogramPlot3 = response.data.data.response;
          } else {
            this.histogramPlotError =  response.data.message;
          }
        } catch (error) {
          console.error("Error loading 2d histogram", error);
          this.histogramPlotError = error.message;
        } finally {
          this.loading_two = false;
        }
      }
    },
    async drawCorrectionProbabilityChart() {
      this.correctionProbabilityChart =null;
      this.correctionProbabilityChartError =null;
      if (this.currentStep === 3 && this.sampleData?.id != null) {
        this.loading_three = true;
        try {
          const response = await axios.post(
            `samples/${this.sampleData.id}/correction_probability_chart`,
            {
              fluid_MW_set_name: "Katz",
            }
          );
          if (response.data && response.data.data && response.data.statusCode==200) {
            this.correctionProbabilityChart = response.data.data.response;
          } else {
            this.correctionProbabilityChartError = response.data.message;
          }
        } catch (error) {
          console.error("Error loading probability chart.", error);
          this.correctionProbabilityChartError =error.message;
        } finally {
          this.loading_three = false;
        }
      }
    },
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newSampleData) {
        this.aiLocalSampleData = JSON.parse(JSON.stringify(newSampleData));
        this.chartsFilter();
        this.drawCorrectionBarPlot();
      },
    },
    // Watcher for currentStep to call the API only once for each step
    currentStep(newStep) {
      this.callApiForStep(newStep);
    },
    switchMole: {
      immediate: true,
      handler(newValue) {
        this.chartsFilter();
      },
    },
  },
};
</script>

<style scoped>
.marker {
  display: inline-block;
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
  margin-left: 5px;
}

/* Shape Styles */
.shape-circle-solid {
  background-color: #4dc671;
  border-radius: 50%;
}

.shape-circle-outline {
  border: 2px solid currentColor;
  border-radius: 50%;
  background: none;
}

.shape-square-solid {
  width: 12px;
  height: 12px;
  background-color: #2a64b4;
}

.shape-square-outline {
  width: 12px;
  height: 12px;
  border: 2px solid #76a715;
  background: none;
}

.shape-diamond-solid {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #c74af6;
}

.shape-diamond-outline {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 2px solid currentColor;
}

.shape-triangle-up-solid {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #180ab7;
}

.shape-triangle-up-outline {
  border-bottom: 1px solid #c46cbe;
  position: relative;
}

.shape-triangle-up-outline::before {
  content: "";
  position: absolute;
  background: #c46cbe;
  left: 3px;
  top: 1px;
  width: 1px;
  height: 12px;
  transform: rotate(30deg);
}

.shape-triangle-up-outline::after {
  content: "";
  position: absolute;
  background: #c46cbe;
  right: 3px;
  top: 1px;
  width: 1px;
  height: 12px;
  transform: rotate(-30deg);
}

.shape-cross-solid {
  position: relative;
  width: 12px;
  height: 12px;
}

.shape-cross-solid:before,
.shape-cross-solid:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #38c7c5;
}

.shape-cross-solid:before {
  transform: rotate(90deg);
}

.shape-cross-outline {
  position: relative;
  width: 12px;
  height: 12px;
}

.shape-cross-outline:before,
.shape-cross-outline:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: none;
  border: 1px solid #bcbbd6;
}

.shape-cross-outline:before {
  transform: rotate(90deg);
}
</style>