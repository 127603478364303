<template>
  <div v-if="!isPermissionLoading && hasPermission('ViewRoles')">
    <DeleteRoleComponent 
      ref="deleteRoleModal" 
      @roleDeleted="deleteRole" 
    />
    <EditRoleModalComponent 
      ref="editRoleModal" 
      :permissions="permissions"
      @updateRole="updateRole" 
      @roleUpdated="fetchRoles"  
    />
    <NewRoleModalComponent ref="newRoleModal" />
    <div class="row no-gutters existing-sec">
      <div class="col-12 col-md-3">
        <h1 class="exist-heading">Existing Roles</h1>
      </div>
      <div class="col-12 col-md-9 mt-3 mt-md-0">
        <div class="table-container">
          <!-- Add loading state -->
          <div v-if="loading" class="text-center py-4">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <!-- Add error state -->
          <div v-else-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
          <!-- Add empty state -->
          <div v-else-if="roles.length === 0" class="text-center py-4">
            No roles found
          </div>
          <!-- Table content -->
          <div v-else>
            <table class="table">
              <thead>
                <tr class="header-row">
                  <th v-for="header in tableHeaders" :key="header.name">
                    {{ header.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(role, index) in roles" :key="index">
                  <td>{{ role.name }}</td>
                  <td class="text-left">
                    <span
                      v-for="permission in role.permissions"
                      :key="permission"
                      :style="{ backgroundColor: getPermissionColor(permission) }"
                      class="permission-wrap"
                    >
                      {{ permission }}
                    </span>
                  </td>
                  <td>{{ role.description || '-' }}</td>
                  <td class="role-action" v-if="!isPermissionLoading && hasPermission('ManageRoles')">
                    <button class="btn btn-link p-0 mr-2" @click="showEditModal(role)" title="Edit Role">
                      <img src="../../assets/images/table/edit.png" alt="Edit"/>
                    </button>
                    <button class="btn btn-link p-0" @click="showModal(role)" title="Delete Role">
                      <img src="../../assets/images/table/trash.png" alt="Delete"/>
                    </button>
                  </td>
                  <td v-else>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteRoleComponent from "./DeleteRoleComponent.vue";
import EditRoleModalComponent from './EditRoleModalComponent.vue';
import NewRoleModalComponent from './NewRoleModalComponent.vue';
import axios from "axios";
import { mapGetters, mapState } from 'vuex';

export default {
  components: { DeleteRoleComponent, EditRoleModalComponent, NewRoleModalComponent },
  name: "RolesTableComponent",
  data() {
    return {
      roles: [],
      loading: false,
      error: null,
      tableHeaders: [
        { name: "roleTitle", label: "Role Title" },
        { name: "permissions", label: "Permissions" },
        { name: "description", label: "Description" },
        { name: "actions", label: "Actions" },
      ],
    };
  },
  props: {
    permissions: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission']),
  },
  methods: {
    getPermissionColor(permission) {
      switch (permission.toLowerCase()) {
        case "manageusers": return "#ff4c4c";
        case "manageroles": return "#28a745";
        case "managefilters": return "#007bff";
        case "managesamples": return "#ffc107";
        case "importsamples": return "#17a2b8";
        case "exportsamplestopvtintelligence": return "#6f42c1";
        case "guest": return "#6c757d";
        case "viewusers": return "#fd7e14";
        default: return "#6c757d";
      }
    },
    showModal(role) {
      this.$refs.deleteRoleModal.openModal(role); 
    },
    showEditModal(role) {
      this.$refs.editRoleModal.openModal(role);
    },
    async fetchRoles() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get('/roles');
        this.roles = response.data.data;
        if (!Array.isArray(this.roles)) {
          throw new Error("Invalid roles data received");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        this.error = "Failed to load roles. Please try again later.";
        this.roles = [];
      } finally {
        this.loading = false;
      }
    },
    updateRole(updatedRole) {
      const roleIndex = this.roles.findIndex(role => role.id === updatedRole.id);
      if (roleIndex !== -1) {
        this.roles.splice(roleIndex, 1, updatedRole);
      }
    },
    deleteRole(roleId) {
     this.fetchRoles();
    }
  },
  mounted() {
    this.fetchRoles();
  },
};
</script>

<style scoped>
.permission-wrap {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  margin: 2px;
  font-size: 0.9em;
}

.role-action button {
  transition: opacity 0.2s;
}

.role-action button:hover {
  opacity: 0.7;
}

.table td {
  vertical-align: middle;
}
</style>
