<template>
  <div>
    <TwoDHistogramExpand ref="histogramChartExpand" />
    <div>
      <div ref="histogram2d" style="width: 100%"></div>
      <button type="button" class="btn expand-btn" @click="showModal">
        <img :src="expand" alt="expand" />
        <span>Expand</span>
      </button>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    xVariable: String,
    yVariable: String,
    chartData: Array, // Data passed from parent component
  },
  data() {
    return {
      expand: require("../../assets/images/table/expand.png"),
    };
  },
  watch: {
    chartData: "drawHistogram2D", // Redraw chart when data changes
  },
  methods: {
    showModal() {
      this.$refs.histogramChartExpand.openModal();
    },
    drawHistogram2D() {
      if (!this.chartData || !this.xVariable || !this.yVariable) {
        return; // Ensure data and variables are available
      }

      const trace = {
        x: this.chartData.x,
        y: this.chartData.y,
        type: "histogram2d",
      };

      const layout = {
        xaxis: { title: this.xVariable },
        yaxis: { title: this.yVariable },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs.histogram2d, [trace], layout, config);
    },
  },
};
</script>