<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#NewRole"
      ref="openModalNewRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="NewRole">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body pt-2">
            <div class="edit-sec">
              <h1>Add Role</h1>
              <div class="input-sec">
                <label for="email">Role <Tarea></Tarea>itle</label>
                <input
                  type="text"
                  v-model="role_title"
                  placeholder="Enter Role Title"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="btn btn-main">Save Role</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewRoleModalComponent",
  data() {
    return {
        role_title: null,
    };
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalNewRole;
      if (elem) {
        elem.click();
      }
    },
  },
};
</script>