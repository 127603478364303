<template>
  <div>
    <div class="table-top-right mt-4 mt-md-0">
      <ToggleModalButtons
      :checkedPercentage="checkedPercentage"
      :checked="checked"
      @update:checkedPercentage="updateIsCheckedPercentage"
      @update:checked="updateIsChecked"
    />
      <button type="button" class="btn" @click="exportData" :disabled="loading">
        <span v-if="loading">
          <span>Exporting...</span>
        </span>
        <span v-else class="btn">
          <img :src="export_data" alt="export" />
          <span>Export</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import ToggleModalButtons from "./ToggleModalButtons.vue";
import * as XLSX from 'xlsx';

export default {
  components: { ToggleModalButtons },
  name: "ComparisonModalRightComponent",
  props: {
    isCheckedPercentage: Boolean,
    isChecked: Boolean,
    sampleData: Object,
  },
  data() {
    return {
      export_data: require("../../../assets/images/table/export.png"),
      line_chart: require("../../../assets/images/table/line-chart.png"),
      loading: false,
      checkedPercentage: this.isCheckedPercentage,
      checked: this.isChecked,
    };
  },
  computed: {
    unitKey() {
      return this.isCheckedPercentage ? "Mole %" : "wt %";
    },
  },
  methods: {
    updateIsCheckedPercentage(value) {
      this.$emit("update:isCheckedPercentage", value);
    },
    updateIsChecked(value) {
      this.$emit("update:isChecked", value);
    },
    async exportData() {
      this.loading = true;
      try {
        const data = this.prepareExcelData(this.sampleData);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data Comparison");
        XLSX.writeFile(wb, "Data Comparison.xlsx");
      } catch (error) {
        console.error('Error exporting data:', error);
      } finally {
        this.loading = false;
      }
    },
    prepareExcelData(data) {
      if (!data.correctedComposition || !data.originalComposition) {
        console.error('Invalid data structure:', data);
        return [];
      }
      const unitKey = this.unitKey;

      // Function to safely apply toFixed to numbers
      const safeToFixed = (value, decimals) => {
        if (value !== undefined && value !== null) {
          return Number(value).toFixed(decimals);
        }
        return "-";
      };

      const calculateDelta = (corrected, uncorrected) => {
        if (
          corrected !== undefined &&
          corrected !== null &&
          uncorrected !== undefined &&
          uncorrected !== null &&
          uncorrected != 0
        ) {
          const delta = ((corrected - uncorrected) / uncorrected * 100).toFixed(1);
          return isNaN(delta) ? "-" : delta + " %";
        }
        return "-";
      };

      // Combine data from corrected and original compositions
      const excelData = data.correctedComposition.composition.map(correctedItem => {
        const originalItem = data.originalComposition.composition.find(
          item => item.component === correctedItem.component
        );

        const flashedGasCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.stockTankGas_MoleFraction, 3)
          : safeToFixed(correctedItem.stockTankGas_MassFraction, 3);
        const flashedGasUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.stockTankGas_MoleFraction, 3)
          : safeToFixed(originalItem?.stockTankGas_MassFraction, 3);
        const flashedGasDelta = calculateDelta(flashedGasCorrected, flashedGasUncorrected);

        const flashedLiquidCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.stockTankOil_MoleFraction, 3)
          : safeToFixed(correctedItem.stockTankOil_MassFraction, 3);
        const flashedLiquidUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.stockTankOil_MoleFraction, 3)
          : safeToFixed(originalItem?.stockTankOil_MassFraction, 3);
        const flashedLiquidDelta = calculateDelta(flashedLiquidCorrected, flashedLiquidUncorrected);

        const overallCorrected = this.isCheckedPercentage
          ? safeToFixed(correctedItem.reservoirLiquid_MoleFraction, 3)
          : safeToFixed(correctedItem.reservoirLiquid_MassFraction, 3);
        const overallUncorrected = this.isCheckedPercentage
          ? safeToFixed(originalItem?.reservoirLiquid_MoleFraction, 3)
          : safeToFixed(originalItem?.reservoirLiquid_MassFraction, 3);
        const overallDelta = calculateDelta(overallCorrected, overallUncorrected);

        return {
          "Component": correctedItem.component || "-",
          "MW (g/mol)": safeToFixed(correctedItem.mw, 1),
          "Density (g/cc)": safeToFixed(correctedItem.density, 3),
          [`Flashed Gas Uncorrected (${unitKey})`]: flashedGasUncorrected,
          [`Flashed Gas Corrected (${unitKey})`]: flashedGasCorrected,
          "Flashed Gas Delta %": flashedGasDelta,
          [`Flashed Liquid Uncorrected (${unitKey})`]: flashedLiquidUncorrected,
          [`Flashed Liquid Corrected (${unitKey})`]: flashedLiquidCorrected,
          "Flashed Liquid Delta %": flashedLiquidDelta,
          [`Overall Uncorrected (${unitKey})`]: overallUncorrected,
          [`Overall Corrected (${unitKey})`]: overallCorrected,
          "Overall Delta %": overallDelta
        };
      });

      // Calculate and add totals row
      const totalsRow = {
        "Component": "Total",
        "MW (g/mol)": "-",
        "Density (g/cc)": "-",
        [`Flashed Gas Uncorrected (${unitKey})`]: this.calculateTotal(excelData, `Flashed Gas Uncorrected (${unitKey})`),
        [`Flashed Gas Corrected (${unitKey})`]: this.calculateTotal(excelData, `Flashed Gas Corrected (${unitKey})`),
        "Flashed Gas Delta %": "-",
        [`Flashed Liquid Uncorrected (${unitKey})`]: this.calculateTotal(excelData, `Flashed Liquid Uncorrected (${unitKey})`),
        [`Flashed Liquid Corrected (${unitKey})`]: this.calculateTotal(excelData, `Flashed Liquid Corrected (${unitKey})`),
        "Flashed Liquid Delta %": "-",
        [`Overall Uncorrected (${unitKey})`]: this.calculateTotal(excelData, `Overall Uncorrected (${unitKey})`),
        [`Overall Corrected (${unitKey})`]: this.calculateTotal(excelData, `Overall Corrected (${unitKey})`),
        "Overall Delta %": "-"
      };

      return [...excelData, totalsRow];
    },

    calculateTotal(data, field) {
      const total = data.reduce((acc, item) => {
        const value = parseFloat(item[field]) || 0;
        return acc + value;
      }, 0);

      if (total === 100) {
        return "100.000";
      }
      return total.toFixed(3);
    },
  },
};
</script>
