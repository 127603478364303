<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#Chart"
      ref="openModalChartExpand"
    ></button>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="Chart"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Data Clusters</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body d-block p-3">
            <free-style-shimmer
              v-if="loading"
              :is-loading="true"
              height="530px"
              width="100%"
              border-radius="12px"
              color="#F6F6F6"
            />
            <div v-else>
              <ChartDataComponent :filterData="filterData" ref="chartComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDataComponent from "./ChartDataComponent.vue";
import Plotly from 'plotly.js-dist';
import $ from 'jquery'
import CircularLoader from '../Loader/CircularLoader.vue';

export default {
  name: "ChartExpandComponent",
  components: {
    ChartDataComponent,
    CircularLoader
  },
  props: {
    filterData: Object,
  },
   data() {
    return {
      loading: true, // Add loading state
    };
  },
  mounted() {
    // Bind Bootstrap modal's 'shown.bs.modal' event
    const modalElement = document.getElementById('Chart');
    if (modalElement) {
      $(modalElement).on('shown.bs.modal', this.onModalShown);
    }
  },
  beforeDestroy() {
    // Remove the event listener to avoid memory leaks
    const modalElement = document.getElementById('Chart');
    if (modalElement) {
      $(modalElement).off('shown.bs.modal', this.onModalShown);
    }
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalChartExpand;
      if (elem) {
         this.loading = true;
        elem.click();
      }
    },
    onModalShown() {
      if (this.$refs.chartComponent && this.$refs.chartComponent.plotContainer) {
        const plotEl = this.$refs.chartComponent.plotContainer;
        Plotly.Plots.resize(plotEl);
        this.loading = false;
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
};
</script>
