<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FieldChart"
      ref="openModalFieldExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="FieldChart">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Fields</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div :ref="chartId"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "FieldExpandComponent",
  props: {
    fieldData: {
      type: Array,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chartId: "fieldPieChartModal",
      total:0,
      clickedLabel:'',
    };
  },
  watch: {
    fieldData: {
      handler(newData) {
        if (newData && newData.length > 0) {
          this.total = newData.reduce((acc, item) => acc + item.totalCorrected, 0);
          this.drawChart();
        }
      },
      immediate: true,
      deep: true
    },
    totalItems(newTotal) {
    this.total = newTotal;
    if (this.$refs[this.chartId] && this.$refs[this.chartId].data) {
      if (this.clickedLabel !== '') {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records (${this.clickedLabel})</span><br>${this.total}`,
          });
        } else {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
          });
        }
        this.clickedLabel='';
      }
    }
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalFieldExpand;
      if (elem) {
        elem.click();
      }
      this.$nextTick(() => {
        this.drawChart(); // Draw the chart when modal opens
      });
    },
    drawChart() {
      const values = this.fieldData.map(item => item.totalCorrected);
      const labels = this.fieldData.map(item => item.field);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.totalItems}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0.5,
        },
        height: 460,
        margin: { t: 0, b: 0, l: 0, r: 0 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);

      this.updateTotalOnLegendClick();
    },
    updateTotalOnLegendClick() {
      this.$refs[this.chartId].on('plotly_restyle', (eventData) => {
        const updatedVisibility = eventData[0]['visible'];

        // Recalculate the total based on the visibility of each slice
        let updatedTotal = 0;
        updatedVisibility.forEach((isVisible, index) => {
          if (isVisible || isVisible === true) {
            updatedTotal += this.fieldData[index].totalCorrected;
          }
        });

        this.total = updatedTotal;

        // Update the annotation with the new total
        Plotly.relayout(this.$refs[this.chartId], {
          'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
        });
      });
    },
  },
};
</script>
