<template>
  <div>
    <TwoDExpandComponent
      ref="twoDExpand"
      :xVariable="selectedXLabel"
      :yVariable="selectedYLabel" 
      :optionY="selectedYOption?.value"
      :optionX="selectedXOption?.value"
    />
    <div class="dashboard-right-bottom-charts mt-3">
      <h6>2D Histograms</h6>
      <div class="row no-gutters mt-2">
        <div class="col-12 col-md-6">
          <label>Select X Variable</label>
          <vue3-select
            v-model="x_variable"
            :options="xOptions.map(option => option.value)"
            :close-on-select="true"
            placeholder="Select x variable"
            @update:modelValue="handleXVariableChange"
          />
        </div>
        <div class="col-12 col-md-6 pl-md-3">
          <label>Select Y Variable</label>
          <vue3-select
            v-model="y_variable"
            :options="yOptions.map(option => option.value)"
            :close-on-select="true"
            placeholder="Select y variable"
            @update:modelValue="handleYVariableChange"
          />
        </div>
      </div>
      <div class="container-fluid twoD-Chart">
        <free-style-shimmer
          v-if="loading"
          :is-loading="true"
          :height="chartHeight + 'px'"
          width="100%"
          border-radius="12px"
          color="#F6F6F6"
        />
        <div v-if="!loading && !histogramData" class="text-center error-message mt-3">
          <p>No chart available.</p>
        </div>
        <div v-if="!loading && histogramData">
          <div class="chart-btns">
            <button type="button" class="btn export-btn csv-btn" @click="exportCSVTwoD">
              <img :src="export_data" alt="export" />
              <span>Download CSV</span>
            </button>
            <button type="button" class="btn export-btn" @click="showModalTwoDHist">
              <img :src="expand" alt="expand" />
              <span>Expand</span>
            </button>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <h6 v-if="histogramData.response.html" class="text-center">
                {{ selectedXOption.value }} Vs {{ selectedYOption.value }}
              </h6>
              <div class="dashboard-histogram-chart">
                <iframe
                  v-if="histogramData.response.html"
                  :srcdoc="histogramData.response.html"
                  :style="{ width: chartWidth + 'px', height: chartHeight + 'px', border: '0'}"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoDExpandComponent from "@/components/Common/TwoDExpandComponent.vue";
import axios from "axios";

export default {
  components: { TwoDExpandComponent },
  props: {
    options: {
      type: Array,
      required: false,
    },
    filterData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      export_data: require("../../../assets/images/table/export.png"),
      expand: require("../../../assets/images/table/expand.png"),
      x_variable: null, 
      y_variable: null, 
      xOptions: [],
      yOptions: [],
      histogramData: null,
      loading: false,
      selectedXLabel: "", 
      selectedYLabel: "", 
      selectedYOption: "",
      selectedXOption:"",
      chartWidth: "", // Define desired width
      chartHeight: "300", // Define desired height
    };
  },
  watch:{
    options: {
      deep: true,
      handler() {
        this.xOptions = this.options;
        this.yOptions = this.options;
        
        // Check localStorage for saved selections
        const savedXVariable = localStorage.getItem('selectedXVariable');
        const savedYVariable = localStorage.getItem('selectedYVariable');
        
        if (savedXVariable && this.options.length > 0) {
          this.x_variable = savedXVariable;
        } else {
          this.x_variable = this.options[0]?.value;
        }
        
        if (savedYVariable && this.options.length > 0) {
          this.y_variable = savedYVariable;
        } else {
          this.y_variable = this.options[1]?.value;
        }
        
        this.fetchHistogramData();
      },
    },
    filterData: {
      deep: true,
      handler() {
        if (this.x_variable && this.y_variable) {
          this.fetchHistogramData();
        }
      }
    }
  },
  methods: {
    setChartWidth() {
      const chartContainer = this.$el.querySelector(".twoD-Chart");
      if (chartContainer) {
        this.chartWidth = chartContainer.clientWidth - 10;
      }
    },
    handleXVariableChange() {
      localStorage.setItem('selectedXVariable', this.x_variable);
      this.fetchHistogramData();
    },
    handleYVariableChange() {
      localStorage.setItem('selectedYVariable', this.y_variable);
      this.fetchHistogramData();
    },
    exportCSVTwoD() {
      if (!this.histogramData.response.chartData || this.histogramData.response.chartData.length === 0) {
        console.error("No data available for CSV export");
        return;
      }
      const headers = Object.keys(this.histogramData.response.chartData[0]).map(header => {
        return header.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, str => str.toUpperCase());
      });

      const originalHeaders = Object.keys(this.histogramData.response.chartData[0]);

      const csvRows = [headers.join(",")]; // Add formatted headers as the first row

      this.histogramData.response.chartData.forEach(row => {
        const csvRow = originalHeaders.map(header => {
          const value = row[header];
          // Format numeric values to 3 decimal places, padding with zeros if necessary
          return typeof value === "number" ? Number(value).toFixed(3) : value;
        });
        csvRows.push(csvRow.join(","));
      });

      const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
      const encodedUri = encodeURI(csvContent);

      // Create a download link and trigger it
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.selectedXLabel} VS ${this.selectedYLabel}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showModalTwoDHist() {
      this.$refs.twoDExpand.openModal();
    },
    async fetchHistogramData() {
      try {
        if (!this.x_variable || !this.y_variable) return;

        this.loading = true;
        this.histogramData = null;

        this.selectedXOption = this.xOptions.find(option => option.value === this.x_variable);
        this.selectedYOption = this.yOptions.find(option => option.value === this.y_variable);
        this.selectedXLabel = this.selectedXOption?.name;
        this.selectedYLabel = this.selectedYOption?.name;

        const xProperty = `"${encodeURIComponent(this.selectedXLabel)}"`;
        const yProperty = `"${encodeURIComponent(this.selectedYLabel)}"`;

        // Add filter parameters to URL
        const filterParams = {
          asset: this.filterData?.asset ? this.filterData.asset.join(',') : "",
          field: this.filterData?.field ? this.filterData.field.join(',') : "",
          reservoir: this.filterData?.reservoir ? this.filterData.reservoir.join(',') : "",
          well: this.filterData?.well ? this.filterData.well.join(',') : "",
          sampleType: this.filterData?.sampleType ? this.filterData.sampleType.join(',') : ""
        };

        const url = `/dataapi/dashboard/2dHistograms?xProperty=${xProperty}&yProperty=${yProperty}&width=${this.chartWidth}&height=${this.chartHeight}&asset="${filterParams.asset}"&field="${filterParams.field}"&reservoir="${filterParams.reservoir}"&well="${filterParams.well}"&sampleType="${filterParams.sampleType}"`;

        const response = await axios.post(url);

        if (response.data && response.data.data) {
          this.histogramData = response.data.data;
        } else {
          console.error("Data not found in response");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    // Check localStorage for saved selections on mount
    const savedXVariable = localStorage.getItem('selectedXVariable');
    const savedYVariable = localStorage.getItem('selectedYVariable');
    
    if (savedXVariable && this.options?.length > 0) {
      this.x_variable = savedXVariable;
    }
    
    if (savedYVariable && this.options?.length > 0) {
      this.y_variable = savedYVariable;
    }
    
    this.setChartWidth();
    window.addEventListener("resize", this.setChartWidth);
    this.fetchHistogramData();
  },
  beforeUnmount() {
    // Clean up resize listener
    window.removeEventListener("resize", this.setChartWidth);
  }
};
</script>

<style scoped>
.dashboard-histogram-chart iframe {
  width: 100%;
  height: 100%;
}
</style>
