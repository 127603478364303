<template>
  <div class="main-page">
    <div class="navbar-wrapper">
      <NavbarComponent />
    </div>
    <div class="container-fluid">
      <MainComponent />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ChartComponent from "../components/Common/ChartComponent.vue";
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import MainComponent from "../components/SampleSummary/MainComponent.vue";
import FooterComponent from "@/components/Layout/FooterComponent.vue";

export default {
  name: "SampleSummary",
  components: {
    NavbarComponent,
    ChartComponent,
    MainComponent,
    FooterComponent,
  },
  data() {
    return {
      expires: '',
      firstName: '',
      lastName: '',
      role: '',
      token: '',
      username: ''
    };
  },
  mounted() {
    
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission'])
  },
  methods: {
 
  },
};
</script>
