<template>
  <div>
    <RoleErrorModalComponent
      ref="errorModalRole"
      errorMessage="Failed to delete Role"
    />
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#DeleteRole"
      ref="openModalDeleteRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="DeleteRole">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 mr-0">
            <img
              src="../../assets/images/modal/delete-tranparent.png"
              alt="cross"
            />
            <h1>Delete Confirmation</h1>
            <div class="role-permission-sec">
              <h4>Role: <span>{{ role ? role.name : 'N/A' }}</span></h4>
              <h6>Permissions</h6>
              <div class="permission-sec">
                <span
                  v-for="perm in role ? role.permissions : []"
                  :key="perm"
                  :style="{ backgroundColor: getPermissionColor(perm) }"
                  class="permission-wrap mr-0"
                >
                  {{ perm }}
                </span>
              </div>
            </div>
            <p>
              Do you really want to delete this role? <br/>Permissions will be
              deleted along with it. This process <br/>cannot be undone.
            </p>
          </div>
          <div class="modal-footer p-0">
            <button type="button" class="btn cancel" data-dismiss="modal" @click="closeModal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="confirmDelete">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RoleErrorModalComponent from "./RoleErrorModalComponent.vue"

export default {
  name: "DeleteRoleComponent",
  components: { RoleErrorModalComponent },
  data() {
    return {
      role: null // Add a property to store the role
    };
  },
  methods: {
    getPermissionClass(permission) {
      return permission ? (permission === 'Read' ? 'bg-red' : (permission === 'Write' ? 'bg-green' : (permission === 'Execute' ? 'bg-blue' : ''))) : '';
    },
     getPermissionColor(permission) {
      switch (permission.toLowerCase()) {
        case "manageusers": return "#ff4c4c";
        case "manageroles": return "#28a745";
        case "managefilters": return "#007bff";
        case "managesamples": return "#ffc107";
        case "importsamples": return "#17a2b8";
        case "exportsamplestopvtintelligence": return "#6f42c1";
        case "guest": return "#6c757d";
        case "viewusers": return "#fd7e14";
        default: return "#6c757d";
      }
    },
    openModal(role) {
      this.role = role; // Set the role data
      var elem = this.$refs.openModalDeleteRole;
      if (elem) {
        elem.click();
      }
    },
    showAlert(message, type = 'success', duration = 3000) {
      this.alert = {
        show: true,
        message,
        type
      };
      setTimeout(() => {
        this.alert.show = false;
      }, duration);
    },
    async confirmDelete() {
      try {
        const response = await axios.delete(`/roles/${this.role.id}`);
        if (response.data.statusCode >= 400) {
          const message = response.data.message;
          this.$refs.errorModalRole.openModal(message);
        } else {
          this.$emit('roleDeleted', {
            statusCode: 200,
            message: 'Role deleted successfully'
          });
          this.showAlert('Role deleted successfully', 'success');
        }
        this.closeModal();
      } catch (error) {
        this.$emit('roleDeleted', {
          statusCode: error.response?.data?.statusCode || 500,
          message: error.response?.data?.message || 'Error deleting role'
        });
        this.showAlert(error.response?.data?.message || 'Error deleting role', 'error');
        this.closeModal();
      }
    },
    closeModal() {
      this.role = null; // Reset role data
      // Close the DeleteRole modal using DOM API instead of jQuery
      const modal = document.getElementById('DeleteRole');
      if (modal) {
        modal.style.display = 'none';
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-red { background-color: red; }
.bg-green { background-color: green; }
.bg-blue { background-color: blue; }
</style>
