<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#OneDChart"
      ref="openModalOneDExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="OneDChart">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Inventory Analysis</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <!-- Grid container for the charts -->
            <div class="expanded-charts-container">
              <!-- Loop through the chart data and render iframes in grid -->
              <div
                v-for="(data, index) in expandedChartData"
                :key="index"
                class="expanded-chart-item"
              >
                <iframe
                  :srcdoc="data.html"
                  style="border: 0; overflow: hidden;"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OneDExpandComponent",
  props: {
    expandedChartData: {
      type: Array,
      required: true, // Accept the chart data passed from the parent component
    },
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalOneDExpand;
      if (elem) {
        elem.click();
      }
    },
  },
};
</script>

<style scoped>
/* Container for the charts displayed in a grid */
.expanded-charts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three charts per row */
  gap: 20px; /* Space between the charts */
}

/* Style for each chart inside the grid */
.expanded-chart-item iframe {
  width: 100%; /* Make iframe take full width of its grid cell */
  height: 150px; /* Set a fixed height for each chart */
}
</style>
