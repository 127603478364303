import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import axios from 'axios';
import SampleSummary from '../pages/SampleSummary.vue';
import ViewSummary from '../pages/ViewSummary.vue';
import AICorrection from "../pages/AICorrection.vue";
import NotFoundPage from '../pages/NotFoundPage.vue'; 
import ErrorPage from '../pages/ErrorPage.vue';
import Login from "../pages/Login.vue";
import SystemManagement from "../pages/SystemManagement.vue"
import Dashboard from "../pages/Dashboard.vue"

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  // {
  //   path: '/',
  //   name: 'SampleSummary',
  //   component: SampleSummary,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/summary/view/:id',
    name: 'ViewSummary',
    component: ViewSummary,
    meta: { requiresAuth: true }
  },
  {
    path: '/sample/import/ai-correction/:id?',
    name: 'AICorrection',
    component: AICorrection,
    meta: { 
      requiresAuth: true,
      permissions: ['ImportSamples']
    }
  },
  {
    path: '/',
    name: 'SampleSummary',
    component: SampleSummary,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage-users',
    name: 'SystemManagment',
    component: SystemManagement
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { 
      requiresAuth: true,
      permissions: ['ViewSamples']
    }
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: NotFoundPage
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  // Function to get query parameters and set token
  const getQueryParams = () => {
    const query = to.query;
    const token = query.token;

    if (token) {
      const userData = {
        expires: query.expires,
        firstName: query.firstName,
        lastName: query.lastName,
        role: query.role,
        token: token,
        username: query.username
      };
      store.commit('setToken', token);
      store.commit('setUser', userData);
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  };

  // Add permission check function
  const checkPermissions = (permissions) => {
    if (!permissions) return true;
    // Don't check permissions while loading
    if (store.state.auth.isPermissionLoading) return false;
    return permissions.every(permission => store.getters.hasPermission(permission));
  };

  try {
    // Wait for tryAutoLogin to complete
    await store.dispatch('tryAutoLogin');

    if (to.matched.some(record => record.meta.requiresAuth)) {
      getQueryParams();

      if (!store.getters.isAuthenticated) {
        next('/login');
      } else {
        // Add permission check here
        if (to.meta.permissions && !checkPermissions(to.meta.permissions)) {
          console.log('Permission check failed');
          next('/');
        } else {
          if (Object.keys(to.query).length) {
            const newUrl = {
              ...to,
              query: {}
            };
            router.replace(newUrl);
          }
          next();
        }
      }
    } else {
      next();
    }
  } catch (error) {
    console.error('Router guard error:', error);
    next('/error');
  }
});



export default router;
