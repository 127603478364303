<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AssetChart"
      ref="openModalAssetExpand"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="AssetChart">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">Assets</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div :ref="chartId"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "AssetExpandComponent",
  props: {
    assetData: {
      type: Array,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chartId: "assetPieChartModal",
      total:0,
      clickedLabel:'',
    };
  },
   watch: {
    assetData: {
      handler(newData) {
        if (newData && newData.length > 0) {
          this.total = newData.reduce((acc, item) => acc + item.totalCorrected, 0);
          this.drawChart();
        }
      },
      immediate: true,
      deep: true
    },
    totalItems(newTotal) {
    this.total = newTotal;
      if (this.$refs[this.chartId] && this.$refs[this.chartId].data) {
        if (this.clickedLabel !== '') {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records (${this.clickedLabel})</span><br>${this.total}`,
          });
        } else {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
          });
        }
        this.clickedLabel='';
      }
    }
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalAssetExpand;
      if (elem) {
        elem.click();
      }
      this.$nextTick(() => {
        this.drawChart(); // Draw the chart when modal opens
      });
    },
    drawChart() {
      const values = this.assetData.map(item => item.totalCorrected);
      const labels = this.assetData.map(item => item.asset);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.totalItems}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0.5,
        },
        height: 400, // Adjust height for modal
        margin: { t: 0, b: 0, l: 0, r: 0 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);
    },
  },
};
</script>
