<template>
  <div v-if="!isPermissionLoading && hasPermission('ViewUsers')">
    <DeleteUserComponent ref="deleteUserModal" @userDeleted="handleUserDeleted"/>
    <div class="alert" :class="alertClass" v-if="alert.show">
      {{ alert.message }}     
    </div>
    <div class="row no-gutters existing-sec">
      <div class="col-12 col-md-3">
        <h1 class="exist-heading">Existing Users</h1>
      </div>
      <div class="col-12 col-md-9 mt-3 mt-md-0">
        <TopSearch @searchChanged="handleSearchChange" @roleChanged="handleRoleChange" />
        <div class="table-container">
          <div v-if="loading" class="text-center py-4">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
          <div v-else-if="users.length === 0" class="text-center py-4">
            No users found
          </div>
          <div v-else>
            <table class="table">
              <thead>
                <tr class="header-row">
                  <th v-for="header in tableHeaders" :key="header.name">
                    {{ header.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.id">
                  <td>{{ user.firstName || '-' }}</td>
                  <td>{{ user.lastName || '-' }}</td>
                  <td>{{ user.username || '-' }}</td>
                  <td>{{ user.usertype || '-' }}</td>
                  <td>
                    <span class="badge badge-primary">{{ user.role || 'No Role' }}</span>
                  </td>
                  <td>
                    <span :class="getStatusClass(user.status)">
                      {{ user.status || 'Unknown' }}
                    </span>
                  </td>
                  <td class="d-flex align-items-center justify-content-center user-action-data">
                    <span class="mr-2 cursor-pointer" @click="showChangePasswordModal(user)">Change password</span>
                    <button 
                      class="btn btn-link p-0 mr-2" 
                      @click="showModal(user)" 
                      title="Edit User"
                      v-if="!isPermissionLoading && hasPermission('ManageUsers')"
                    >
                      <img src="../../assets/images/table/edit.png" alt="Edit"/>
                    </button>
                    <button 
                      class="btn btn-link p-0" 
                      @click="showDeleteConfirmation(user)" 
                      title="Delete User"
                      v-if="!isPermissionLoading && hasPermission('ManageUsers')"
                    >
                      <img src="../../assets/images/table/trash.png" alt="Delete"/>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <EditUserModalComponent 
          ref="editUserModal" 
          :user="selectedUser" 
          @userUpdated="handleUserUpdated"
          @apiResponse="handleApiResponse"
        />
        <ChangePassowrdModalComponent :user="selectedUser" ref="changePasswordModal"/>
      </div>
    </div>
  </div>
</template>

<script>
import EditUserModalComponent from './EditUserModalComponent.vue';
import DeleteUserComponent from './DeleteUserComponent.vue';
import TopSearch from './TopSearchComponent.vue';
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import ChangePassowrdModalComponent from './ChangePassowrdModalComponent.vue';

export default {
  components: { 
    TopSearch, 
    EditUserModalComponent,
    DeleteUserComponent,
    ChangePassowrdModalComponent
  },
  name: "UserTableComponent",
  data() {
    return {
      tableHeaders: [
        { name: "firstName", label: "First Name" },
        { name: "lastName", label: "Last Name" },
        { name: "username", label: "Email" },
        { name: "usertype", label: "User Type" },
        { name: "role", label: "Roles" },
        { name: "status", label: "Status" },
        { name: "actions", label: "Actions" },
      ],
      searchQuery: '',
      selectedUser: null,
      users: [],
      selectedRole: '',
      userToDelete: null,
      loading: false,
      error: null,
      debounceTimer: null,
      alert: {
        show: false,
        message: '',
        type: 'success'
      }
    };
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission']),
    alertClass() {
      return {
        'alert-success': this.alert.type === 'success',
        'alert-danger': this.alert.type === 'error',
        'alert-warning': this.alert.type === 'warning'
      }
    }
  },
  methods: {
    handleSearchChange(searchQuery) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.searchQuery = searchQuery;
        this.fetchUsers();
      }, 300);
    },

    handleRoleChange(selectedRole) {
      this.selectedRole = selectedRole;
      this.fetchUsers();
    },

    getStatusClass(status) {
      switch(status?.toLowerCase()) {
        case 'active':
          return 'badge badge-success';
        case 'inactive':
          return 'badge badge-warning';
        case 'suspended':
          return 'badge badge-danger';
        default:
          return 'badge badge-secondary';
      }
    },

    showAlert(message, type = 'success', duration = 3000) {
      this.alert = {
        show: true,
        message,
        type
      };
      setTimeout(() => {
        this.alert.show = false;
      }, duration);
    },

    handleApiResponse(response) {
      if (response.statusCode >= 400) {
        this.showAlert(response.message, 'error');
      } else {
        this.showAlert(response.message, 'success');
        this.fetchUsers(); // Refresh only on success
      }
    },

    async fetchUsers() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get('/users', {
          params: {
            username: this.searchQuery ? `"${this.searchQuery}"` : '',
            role: this.selectedRole ? `"${this.selectedRole}"` : ''
          }
        });
        
        if (response.data.statusCode >= 400) {
          throw new Error(response.data.message);
        }
        
        this.users = Array.isArray(response.data.data) ? response.data.data : [];
      } catch (error) {
        console.error("Error fetching users:", error);
        this.error = error.message || "Failed to load users. Please try again later.";
        this.users = [];
      } finally {
        this.loading = false;
      }
    },

    showModal(user) {
      this.selectedUser = { ...user };
      this.$emit("userData", this.selectedUser);
      this.$refs.editUserModal.openModal();
    },

    showChangePasswordModal(user) {
      this.$refs.changePasswordModal.openModal(user);
    },

    handleUserUpdated(response) {
      if (response.statusCode >= 400) {
        this.showAlert(response.message, 'error');
        return;
      }
      // this.showAlert('User updated successfully');
      this.fetchUsers();
    },

    showDeleteConfirmation(user) {
      this.userToDelete = user;
      this.$refs.deleteUserModal.openModal(user);
    },

    handleUserDeleted(response) {
      if (response.statusCode >= 400) {
        this.showAlert(response.message, 'error');
        return;
      }
      
      // this.showAlert('User successfully deleted', 'success');
      this.fetchUsers(); // Fetch updated user list after successful deletion
    },

  },
  mounted() {
    this.fetchUsers();
    // Listen for userData event from AddUserComponent
    window.addEventListener('userData', this.handleUserAdded);
  },
  beforeDestroy() {
    clearTimeout(this.debounceTimer);
    // Clean up event listener
    window.removeEventListener('userData', this.handleUserAdded);
  }
};
</script>
