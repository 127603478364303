<template>
  <div>
    <div class="main-page">
      <div class="navbar-wrapper">
        <NavbarComponent />
      </div>
      <div class="container-fluid main-wrapper">
        <div class="row no-gutters">
          <div class="col-12">
            <div v-if="loading" class="loader-overlay">
              <CircularLoader />
            </div>
            <ManagementTabsComponent />
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import FooterComponent from "../components/Layout/FooterComponent.vue";
import ManagementTabsComponent from '../components/SystemManagement/ManagementTabsComponent.vue';

export default {
  name: "SystemManagment",
  components: {
    NavbarComponent,
    FooterComponent,
    ManagementTabsComponent,
  },
};
</script>
