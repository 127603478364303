<template>
  <div>
    <ChartExpandComponent ref="expandChart" :filterData="filterData" />
    <div class="top-content">
      <h6>Data Clusters</h6>
      <!-- <IconComponent /> -->
    </div>
    <div class="chart-wrapper-inner">
      <ChartDataComponent :filterData="filterData"/>
      <div class="chart-btns">
        <button type="button" class="btn export-btn" @click="exportData" :disabled="loading">
          <span v-if="loading">
          <span class="ml-2">Exporting...</span>
          </span>
          <span v-else class="export-sec">
          <img :src="export_data" alt="export" />
          <span class="ml-2">Export</span>
          </span>
        </button>
        <button type="button" class="btn export-btn" @click="showModal">
          <img :src="expand" alt="expand" />
          <span>Expand</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

// import IconComponent from "./IconComponent.vue";
import axios from 'axios';
import ChartDataComponent from "./ChartDataComponent.vue";
import ChartExpandComponent from './ChartExpandComponent.vue';

export default {
  name: 'ChartComponent',
  components: {
    // IconComponent,
    ChartDataComponent,
    ChartExpandComponent
  },
  data() {
    return {
      expand: require("../../assets/images/table/expand.png"),
      export_data: require("../../assets/images/table/export.png"),
      loading: false,
    }
  },
  props: {
    filterData: Object,
  },
  methods: {
     showModal() {
      this.$refs.expandChart.openModal();
    },
    async exportData() {
        this.loading = true;
        try {  
          const response = await axios.get(`samples/cluster-data`, {
              params: {
                asset: this.filterData.asset.length > 0 ? JSON.stringify(this.filterData.asset) : '',
                field: this.filterData.field.length > 0 ? JSON.stringify(this.filterData.field) : '',
                well: this.filterData.well.length > 0 ? JSON.stringify(this.filterData.well) : '',
                reservoir: this.filterData.reservoir.length > 0 ? JSON.stringify(this.filterData.reservoir) : '',
                sampleType: this.filterData.sampleType.length > 0 ? JSON.stringify(this.filterData.sampleType) : '',
                fluidType: this.filterData.fluidType.length > 0 ? JSON.stringify(this.filterData.fluidType) : '',
              }
            });

            const data = response.data.data;

            const correctedFluids = [];
            const uncorrectedFluids = [];
            const correctedClusterCenters = [];
            const uncorrectedClusterCenters = [];

            // Separate data based on isAnchor and corrected/uncorrected
            if (data.fluids) {
              data.fluids.forEach(fluid => {
                if (fluid.corrected && typeof fluid.corrected === 'object') {
                  correctedFluids.push(fluid.corrected);
                }
                if (fluid.uncorrected && typeof fluid.uncorrected === 'object') {
                  uncorrectedFluids.push(fluid.uncorrected);
                }
              });
            }
            if (data.clusterCenters) {
              data.clusterCenters.forEach(cluster => {
                if (cluster.corrected && typeof cluster.corrected === 'object') {
                  correctedClusterCenters.push(cluster.corrected);
                }
                if (cluster.uncorrected && typeof cluster.uncorrected === 'object') {
                  uncorrectedClusterCenters.push(cluster.uncorrected);
                }
              });
            }
          const csvData = this.convertToCSV(data,correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters);
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'Cluster Data.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error exporting data:', error);
        } finally {
          this.loading = false;
        }
      },
      convertToCSV(data, correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters) {
            const csvRows = [];

            // Add headers
            csvRows.push("Asset,Field, Well, Reservoir, Container ID, Corrected Fluids,,,Uncorrected Fluids,,,Asset,Field, Well, Reservoir, Container ID,Corrected Cluster Centers,,,Uncorrected Cluster Centers,,");
            csvRows.push(",,,,,(C36+ Density),(C36+ wt%),(C36+ MW),(C36+ Density),(C36+ wt%),(C36+ MW),,,,,,(C36+ Density),(C36+ wt%),(C36+ MW),(C36+ Density),(C36+ wt%),(C36+ MW)");
            // Get the maximum length of the data arrays to ensure all data is included
            const maxLength = Math.max(correctedFluids.length, uncorrectedFluids.length, correctedClusterCenters.length, uncorrectedClusterCenters.length);

            // Loop over the maximum length and construct rows
            for (let i = 0; i < maxLength; i++) {
              const assetFluid = data.fluids[i] ? JSON.stringify(data.fluids[i]?.asset) : '';
              const fieldFluid = data.fluids[i] ? JSON.stringify(data.fluids[i]?.field) : '';
              const wellFluid = data.fluids[i] ? JSON.stringify(data.fluids[i]?.well) : '';
              const reservoirFluid = data.fluids[i] ? JSON.stringify((data.fluids[i]?.reservoir?data.fluids[i]?.reservoir:'')) : '';
              const containerIdFluid = data.fluids[i] ? JSON.stringify(data.fluids[i]?.containerID) : '';
              const assetCluster = data.clusterCenters[i] ? JSON.stringify(data.clusterCenters[i]?.asset) : '';
              const fieldCluster = data.clusterCenters[i] ? JSON.stringify(data.clusterCenters[i]?.field) : '';
              const wellCluster = data.clusterCenters[i] ? JSON.stringify(data.clusterCenters[i]?.well) : '';
              const reservoirCluster = data.clusterCenters[i] ? JSON.stringify((data.clusterCenters[i]?.reservoir?data.clusterCenters[i]?.reservoir:'')) : '';
              const containerIdCluster = data.clusterCenters[i] ? JSON.stringify(data.clusterCenters[i]?.containerID) : '';
              const correctedFluidc36plus_Density = correctedFluids[i] ? JSON.stringify(correctedFluids[i]?.c36plus_Density) : '';
              const correctedFluidc36plus_MassFraction = correctedFluids[i] ? JSON.stringify(correctedFluids[i]?.c36plus_MassFraction) : '';
              const correctedFluidc36c36plus_MW = correctedFluids[i] ? JSON.stringify(correctedFluids[i]?.c36plus_MW) : '';
              const uncorrectedFluidc36plus_MassFraction = uncorrectedFluids[i] ? JSON.stringify(uncorrectedFluids[i]?.c36plus_MassFraction) : '';
              const uncorrectedFluidc36plus_Density = uncorrectedFluids[i] ? JSON.stringify(uncorrectedFluids[i]?.c36plus_Density) : '';
              const uncorrectedFluidc36plus_MW = uncorrectedFluids[i] ? JSON.stringify(uncorrectedFluids[i]?.c36plus_MW) : '';
              const correctedClusterc36plus_MassFraction = correctedClusterCenters[i] ? JSON.stringify(correctedClusterCenters[i]?.c36plus_MassFraction) : '';
              const correctedClusterc36plus_Density = correctedClusterCenters[i] ? JSON.stringify(correctedClusterCenters[i]?.c36plus_Density) : '';
              const correctedClusterc36plus_MW = correctedClusterCenters[i] ? JSON.stringify(correctedClusterCenters[i]?.c36plus_MW) : '';
              const uncorrectedClusterc36plus_MassFraction = uncorrectedClusterCenters[i] ? JSON.stringify(uncorrectedClusterCenters[i]?.c36plus_MassFraction) : '';
              const uncorrectedClusterc36plus_Density = uncorrectedClusterCenters[i] ? JSON.stringify(uncorrectedClusterCenters[i]?.c36plus_Density) : '';
              const uncorrectedClusterc36plus_MW = uncorrectedClusterCenters[i] ? JSON.stringify(uncorrectedClusterCenters[i]?.c36plus_MW) : '';

              csvRows.push(`${assetFluid},${fieldFluid},${wellFluid},${reservoirFluid},${containerIdFluid},${correctedFluidc36plus_Density},${correctedFluidc36plus_MassFraction},${correctedFluidc36c36plus_MW},${uncorrectedFluidc36plus_Density},${uncorrectedFluidc36plus_MassFraction},${uncorrectedFluidc36plus_MW},${assetCluster},${fieldCluster},${wellCluster},${reservoirCluster},${containerIdCluster},${correctedClusterc36plus_Density},${correctedClusterc36plus_MassFraction},${correctedClusterc36plus_MW},${uncorrectedClusterc36plus_Density},${uncorrectedClusterc36plus_MassFraction},${uncorrectedClusterc36plus_MW}`);
            }

            return csvRows.join('\n');
      }

  }
}
</script>
