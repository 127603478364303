<template>
  <div class="row">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <div class="search-container">
        <button class="search-btn">
          <img src="../../assets/images/userRoles/search.png" alt="search" />
        </button>
        <input
          v-model="search"
          type="text"
          placeholder="Search with User"
          class="search-input"
          @input="onSearchChange"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 pl-md-0">
      <vue3-select
        v-model="select_roles"
        :options="roles"
        placeholder="Search with Roles"
        @update:modelValue="onRoleChange"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "TopSearch",
  data() {
    return {
      search: '', // Search input
      select_roles: '', // Role selection
      roles: [], // Roles fetched from API
    }
  },
  methods: {
    async fetchRoles() {
      try {
        const response = await axios.get('/roles');
        this.roles = response.data.data.map(role => role.name); // Assuming your roles have a "name" field
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    },
    onSearchChange() {
      this.$emit('searchChanged', this.search); // Emit the search query
    },
    onRoleChange() {
      this.$emit('roleChanged', this.select_roles); // Emit the selected role
    }
  },
  mounted() {
    this.fetchRoles(); // Fetch roles when the component is mounted
  }
};
</script>
