<template>
  <div class="row no-gutters">
    <div class="col-12 summary-top-wrap">
        <MainTopComponent
          :sortColumn="sortColumn"
          :sortDirection="sortDirection"
          :filterData="filterData" 
          @isCorrected="isCorrected" 
          @isAnchorPoint="isAnchorPoint" 
          @apply-filter="applyFilter" 
          :pagination="pagination" 
        />
    </div>
    <div class="composition-page-wrap">
      <div class="row no-gutters">
        <div class="col-12 col-xl-7 custom-padding no-padding-left">
          <div class="chart-wrapper">
            <ChartComponent 
            :filterData="filterData"
            />
          </div>
        </div>
        <div class="col-12 col-xl-5 no-padding-right">
          <div class="asset-field-wrapper">
            <AssetComponent :filter="filterData" :localLoading="localLoading" :assetData="assetData" :totalItems="pagination.totalItems" @apply-filter="applyFilterFromPieChart" />
            <FieldComponent :filter="filterData" :localLoading="localLoading" :fieldData="fieldData" :totalItems="pagination.totalItems" @apply-filter="applyFilterFromPieChart" />
          </div>
        </div>
      </div>
    <!-- </div> -->
    <div class="table-wrapper" v-if="!isPermissionLoading && hasPermission('ManageUsers')">
        <div class="container-fluid">
          <div v-if="loading" class="loader-overlay">
            <CircularLoader />
          </div>
          <div v-else>
            <div class="row no-gutters align-items-center">
              <div class="col-12">
                <TableDataComponent 
                  :tableData="tableData" 
                  :pagination="pagination" 
                  @changePage="fetchData"
                  @changeItemsPerPage="updateItemsPerPage"
                  @sortTable="updateSorting"
                  :sortColumn="sortColumn"
                  :filterData="filterData"
                  @apply-filter-table="applyFilter"
                  :sortDirection="sortDirection"
                />
              </div>
            </div>
            <div v-if="error" class="error-message">{{ errorMessage }}</div>
            <div v-if="!error && tableData.length === 0" class="no-data-message">No data found</div>
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssetComponent from "@/components/Common/AssetComponent.vue";
import FieldComponent from "@/components/Common/FieldComponent.vue";
import ChartComponent from "@/components/Common/ChartComponent.vue";
import TableDataComponent from './TableDataComponent.vue';
import CircularLoader from '../Loader/CircularLoader.vue';
import axios from 'axios';
import MainTopComponent from './MainTopComponent.vue';

export default {
  Name: "MainComponent",
  components: {
                MainTopComponent, 
                TableDataComponent, 
                CircularLoader,
                AssetComponent,
                ChartComponent,
                FieldComponent
              },
  data() {
    return {
      assetData: [],
      fieldData: [],
      filterData: {
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        fluidType:[],
        isCorrected:'',
        isAnchorPoint:'',
        filterType:'',
      },
      tableData: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalPages: 0,
        totalItems: 0
      },
      loading: false,
      localLoading:false,
      error: false,
      errorMessage: '',
      sortColumn: '',
      sortDirection: '',
    };
  },
  methods: {
    async applyFilterFromPieChart(filterData)
    {
      this.loading = true;
      this.filterData=filterData;
      this.sortColumn=(this.filterData.filterType)?this.sortColumn:'';
      this.sortDirection=(this.filterData.filterType)?this.sortDirection:'';
      await this.fetchData(1);
      await this.fetchMetrics();
    },
    async applyFilter(filterData)
    {
      this.loading = true;
      this.filterData=filterData;
      this.sortColumn=(this.filterData.filterType)?this.sortColumn:'';
      this.sortDirection=(this.filterData.filterType)?this.sortDirection:'';
      await this.fetchData(1);
      this.fetchMetrics();
    },
    async isCorrected(isCorrected)
    {
      this.loading = true;
       this.filterData.isCorrected=isCorrected;
      await this.fetchData(1);
       this.fetchMetrics();
    },
    async isAnchorPoint(isAnchorPoint)
    {
      this.loading = true;
      this.filterData.isAnchorPoint=isAnchorPoint;
       await this.fetchData(1);
       this.fetchMetrics();
    },
    async updateSorting({ column, direction }) {
      this.sortColumn = column;
      this.sortDirection = direction;
      await this.fetchData(1);
    },
    async fetchData(page = 1) {
      // this.loading = true;
      this.error = false;
      this.errorMessage = '';
      try {
        const response = await axios.get(`samples/summary`, {
          params: {
            pageNumber: page,
            pageSize: this.pagination.itemsPerPage,
            asset:(this.filterData.asset.length>0)?JSON.stringify(this.filterData.asset):'',
            field:(this.filterData.field.length>0)?JSON.stringify(this.filterData.field):'',
            well:(this.filterData.well.length>0)?JSON.stringify(this.filterData.well):'',
            reservoir:(this.filterData.reservoir.length>0)?JSON.stringify(this.filterData.reservoir):'',
            fluidType:(this.filterData.fluidType.length>0)?JSON.stringify(this.filterData.fluidType):'',
            sampleType:(this.filterData.sampleType.length>0)?JSON.stringify(this.filterData.sampleType):'',
            name:(this.filterData.sample.length>0)?JSON.stringify(this.filterData.sample):'',
            isCorrected:this.filterData.isCorrected,
            isAnchorPoint:this.filterData.isAnchorPoint,
            sortColumn: (this.sortColumn)?JSON.stringify(this.sortColumn):'',
            sortDirection: (this.sortDirection)?JSON.stringify(this.sortDirection):'',
          }
        });
        const data = response.data;
        this.tableData = data.data;
        this.pagination.totalItems = data.pagination.totalRecords;
        this.pagination.totalPages = data.pagination.totalPages;
        this.pagination.currentPage = page;
        if (this.tableData.length === 0) {
          this.error = true;
          this.errorMessage = 'No data found';
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Error fetching data";
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    updateItemsPerPage(itemsPerPage) {
      this.loading = true;
      this.pagination.itemsPerPage = itemsPerPage;
      this.fetchData(1); // Reset to page 1 whenever items per page changes
    },
    async fetchMetrics() {
      this.localLoading=true;
      await axios.get("samples/metrics",{
        params: {
            scope: '',
            // scope: this.filterData.asset.length > 0 ? '"asset"' : '',
            asset: this.filterData.asset.length > 0 ? JSON.stringify(this.filterData.asset) : '',
            field: this.filterData.field.length > 0 ? JSON.stringify(this.filterData.field) : '',
            well: this.filterData.well.length > 0 ? JSON.stringify(this.filterData.well) : '',
            reservoir: this.filterData.reservoir.length > 0 ? JSON.stringify(this.filterData.reservoir) : '',
            fluidType: this.filterData.fluidType.length > 0 ? JSON.stringify(this.filterData.fluidType) : '',
            sampleType: this.filterData.sampleType.length > 0 ? JSON.stringify(this.filterData.sampleType) : '',
          }
      })
        .then((response) => {
          const data = response.data.data;
          this.assetData = data.filter(item => item.scope === 'asset');
          if(this.filterData.field.length)
          {
            this.filterData.asset=this.assetData.map(item => item.asset);
          }
          this.fieldData = data.filter(item => item.scope === 'field');
          this.localLoading=false;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.localLoading=false;
        });
    },
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission'])
  },
  created() {
  },
  mounted()
  {
    const savedPage = localStorage.getItem('selectedPageSize');
    if (savedPage) {
      this.pagination.itemsPerPage = Number(savedPage); 
    }
    this.loading = true;
    this.fetchData();
    this.fetchMetrics();
  },
};
</script>
