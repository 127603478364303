<template>
  <div class="sample-select-wrapper">
    <div class="sample-selection">
      <form>
        <div class="row no-gutters">
          <div class="col-12">
            <h6>Asset</h6>
            <!-- Vue3-Select for asset selection -->
            <vue3-select
              v-model="asset"
              :options="filteredOptionsAssets"
              :close-on-select="true"
              placeholder="Select an Asset"
              @update:modelValue="handleAssetChange"
            />

            <!-- Show input field only when "Add New Asset" is selected -->
            <div v-if="showAddNewAssetField" class="my-3">
              <input
                v-model="newAsset"
                class="form-control"
                type="text"
                placeholder="Enter new Asset"
              />
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col">
            <h6>Select sample from file</h6>
            <div class="form-control">
              <input
                type="file"
                class="form-control-file"
                @change="handleFileUpload"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="table-top-right justify-content-start">
      <button type="button" class="btn" @click="next" :disabled="!selectedFile">
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { showErrorToast } from "../../services/toastService";

export default {
  name: "ImportFromDatabaseComponent",
  data() {
    return {
      asset: null, // The selected asset from the dropdown
      newAsset: "", // The new asset entered in the input field
      showAddNewAssetField: false, // Controls whether the input field is shown
      selectedFile: null, // File selected by the user
      assets: [], // List of assets
    };
  },
  computed: {
    // Filter the assets and add the "Add New Asset" option
    filteredOptionsAssets() {
      return [
        ...this.assets,
        { label: "Add New Asset", value: "add_new_asset" }, // Custom option to add a new asset
      ];
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.size > 0) {
        this.selectedFile = file;
      } else {
        showErrorToast("Uploading failed, Please select a non-empty file.");
        this.selectedFile = null;
        event.target.value = ""; // Clear the input
      }
    },
    next() {
      if (this.selectedFile && (this.asset || this.newAsset)) {
        // Emit the new asset value if the input is shown and filled
        const assetToSend =
          this.showAddNewAssetField && this.newAsset
            ? this.newAsset
            : this.asset;

        this.$emit(
          "open-sample-correction-with-file",
          this.selectedFile,
          assetToSend // Send the correct asset value
        );

        // Clear form after emitting
        this.selectedFile = null;
        this.asset = null;
        this.newAsset = "";
      } else {
        showErrorToast("Uploading failed, Select a file.");
      }
    },
    fetchFilterOptions() {
      axios
        .get("filter/options")
        .then((response) => {
          const data = response.data.data;
          this.assets = data.locations.asset ? data.locations.asset : [];
        })
        .catch((error) => {
          console.error("Error fetching filter options:", error);
        });
    },
    handleAssetChange(value) {
      if (value && value.value === "add_new_asset") {
        this.showAddNewAssetField = true;
        this.asset = ""; 
      } else {
        this.showAddNewAssetField = false; 
        this.asset = value ? value : null; 
      }
    },
  },
  mounted() {
    this.fetchFilterOptions();
  },
};
</script>
