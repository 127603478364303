<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#TwoDChart" ref="openModalTwoDExpand"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="TwoDChart">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header flex-row">
            <h5 class="modal-title">2D Histrogram</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <!-- Adding the iframe to load the treemap chart -->
            <div class="dashboard-right-bottom-charts mt-3">
              <div class="container-fluid">
                <free-style-shimmer v-if="loading" :is-loading="true" height="420px" width="100%" border-radius="12px"
                  color="#F6F6F6" />

                <div v-if="!loading && !histogramData" class="text-center mt-3">
                  <p>No chart available.</p>
                </div>

                <div v-if="!loading && histogramData">
                  <div class="row no-gutters">
                    <div class="col-12">
                      <h6 v-if="histogramData.response.html" class="text-center">{{ this.optionX }} Vs {{ this.optionY }}
                      </h6>
                      <div class="dashboard-histogram-chart">
                        <iframe v-if="histogramData.response.html" :srcdoc="histogramData.response.html" :style="{
                          width: '100%',
                          border: 0,
                        }" scrolling="no"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TwoDExpandComponent",
  data() {
    return {
      histogramData: null,
      loading: false,
      selectedXLabel: "",
      selectedYLabel: "",
    }
  },
  props: {
    // histogramData: {
    //   type: String,
    //   required: true, // Accept the chart data passed from parent
    // },
    xVariable: {
      type: null,  // This allows any data type
      default: () => null
    },
    yVariable: {
      type: null,  // This allows any data type
      default: () => null
    },
    optionX: {
      type: null,  // This allows any data type
      default: () => null
    },
    optionY: {
      type: null,  // This allows any data type
      default: () => null
    },
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalTwoDExpand;
      if (elem) {
        this.fetchHistogramData();
        elem.click();
      }
    },
    async fetchHistogramData() {
      if (!this.xVariable || !this.yVariable) return; // Ensure both are selected

      this.loading = true;
      this.histogramData = null;

      try {
        const url = `/dataapi/dashboard/2dHistograms?xProperty="${this.xVariable}"&yProperty="${this.yVariable}"&height=375`;

        const response = await axios.post(url);

        if (response.data && response.data.data) {
          this.histogramData = response.data.data;
        } else {
          console.error("Data not found in response");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-body iframe {
  width: 700px;
  height: 375px;
}

.modal-body iframe .main-svg {
  width: 100%;
}
</style>