<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ChangePassword"
      ref="openModalChangePassword"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="ChangePassword">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="row no-gutters align-items-center">
            <h5 class="modal-title col-9">Change Password ({{ user_email }})</h5>
            <button
              type="button"
              class="close col-3 d-flex align-items-center justify-content-end"
              ref="ChangePasswordClose"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 mr-0">
            <div class="input-sec position-relative">
              <label for="new-password">New Password</label>
              <div class="input-group">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Enter New Password"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="togglePasswordVisibility('password')"
                  >
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                    ></i>
                  </button>
                </div>
              </div>
              <small v-if="password && !isPasswordValid" class="text-danger validation-error">
                Password must be minimum 8 characters, with at least one Upper case, lower case, number, and symbol.
              </small>
            </div>
            <div class="input-sec position-relative">
              <label for="confirm-password">Confirm Password</label>
              <div class="input-group">
                <input
                  v-model="confirm_password"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Enter Confirm Password"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="togglePasswordVisibility('confirmPassword')"
                  >
                    <i
                      :class="
                        showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                      "
                    ></i>
                  </button>
                </div>
              </div>
              <small
                v-if="confirm_password && !isPasswordMatch"
                class="text-danger validation-error"
              >
                Passwords do not match.
              </small>
            </div>
          </div>

          <div class="modal-footer p-0">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-main"
              @click="updatePassword"
              :disabled="
                isLoading || !password || !confirm_password || !isPasswordMatch
              "
            >
              <span v-if="isLoading">Updating...</span>
              <div v-else>
                <span>Update</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePasswordModalComponent",
  data() {
    return {
      password: "",
      confirm_password: "",
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
      user: null,
      user_id: "",
      user_email: ""
    };
  },
  computed: {
    isPasswordValid() {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordRegex.test(this.password);
    },
    isPasswordMatch() {
      return this.password === this.confirm_password;
    },
  },
  methods: {
    openModal(user) {
      console.log(user)
      this.user = user;
      this.user_id = this.user.id;
      this.user_email = this.user.username;
      const elem = this.$refs.openModalChangePassword;
      if (elem) {
        elem.click();
        this.password = "";
        this.confirm_password = "";
      }
    },
    togglePasswordVisibility(field) {
      if (field === "password") {
        this.showPassword = !this.showPassword;
      } else if (field === "confirmPassword") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
    async updatePassword() {
      if (!this.isPasswordValid) {
       return;
      } else {
        const passwordData = {
          data: {
            user: {
              password: this.password,
            },
          },
        };
        this.isLoading = true;
        try {
          // Make the POST request to add the user
          const response = await axios.put(
            `/users/${this.user.id}`,
            passwordData
          );
          console.log(response.data);
          const elem = this.$refs.ChangePasswordClose;
          if (elem) {
            elem.click();
          }
        } catch (error) {
          console.error("Error updating password:", error);
          // Handle error appropriately
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
