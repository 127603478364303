<template>
  <div class="dashboard-top-left">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 top-head-btn">
          <div class="row no-gutters custom-Dashboard">
            <div>
              <h6>Dashboard</h6>
              <!-- Reset button functionality -->
              <button
                type="button"
                class="btn reset-btn main-btn mt-2"
                @click="resetFilters"
              >
                <img :src="reset" alt="reset_data" />
                <span>Reset</span>
              </button>
            </div>
            <div class="data-strip mb-1" v-if="hasFieldValues">
              <div>
                <div
                  v-for="(field, index) in fields"
                  :key="index"
                  class="data-field dashboard-field"
                >
                  <div class="field-label">
                    <span class="label">{{ field.label }}</span>
                    <span class="value">{{ field.value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2">
          <AssetComponent
            :filter="filterData"  
            :assetData="assetData"
            :totalItems="totalItems"
            :localLoading="localLoading"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-xl-2">
          <FieldComponent
            :filter="filterData"
            :fieldData="fieldData"
            :totalItems="totalItems"
            :localLoading="localLoading"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2 pr-xl-0">
          <ReserviorComponent
            :filter="filterData"
            :reservoirData="reservoirData"
            :totalItems="totalItems"
            :localLoading="localLoading"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AssetComponent from "@/components/Common/AssetComponent.vue";
import FieldComponent from "@/components/Common/FieldComponent.vue";
import ReserviorComponent from "@/components/Common/ReservoirComponent.vue";

export default {
  name: "DashboardLeftTopComponent",
  components: {
    AssetComponent,
    FieldComponent,
    ReserviorComponent,
  },
  data() {
    return {
      assetData: [],
      fieldData: [],
      reservoirData: [],
      filterData: {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
        fluidType: [],
        isCorrected:null,
        isAnchorPoint:null,
        filterType:null,
      },
      totalItems: 0,
      reset: require("../../../assets/images/modal/refresh-2.png"),
      localLoading:false,
    };
  },
  computed: {
    fields() {
      return [
        {
          label: "Asset",
          value: (this.filterData.asset || []).join(", "),
        },
        {
          label: "Field",
          value: (this.filterData.field || []).join(", "),
        },
        {
          label: "Reservoir",
          value: (this.filterData.reservoir || []).join(", "),
        },
        {
          label: "Well",
          value: (this.filterData.well || []).join(", "),
        },
        {
          label: "Sample Type",
          value: (this.filterData.sampleType || []).join(", "),
        },
        {
          label: "Fluid Type",
          value: (this.filterData.fluidType || []).join(", "),
        },
        {
          label: "Sample",
          value: (this.filterData.sample || []).join(", "),
        },
      ].filter((field) => field.value); // Only include filters that have values
    },
    hasFieldValues() {
      return this.fields.length > 0; // Check if any field has a value
    },
  },

  methods: {
    async applyFilterFromPieChart(newFilterData) {
      this.filterData=newFilterData;
      try {
        await this.fetchMetrics();
        this.$emit("update-filter", this.filterData);
      } finally {
      }
      
    },

    // Reset filters and fetch default data
    async resetFilters() {
      this.loading = true;
      // Clear all filter data
      this.filterData = {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
        fluidType: [],
        isCorrected:null,
        isAnchorPoint:null,
        filterType:null,
      };
      await this.fetchMetrics(); 
      this.$emit("update-filter", this.filterData); 
      
    },

    // Fetch metrics data based on current filters
    async fetchMetrics() {
      try {
        this.localLoading=true;
        const response = await axios.get("samples/metrics", {
          params: {
            asset:
              this.filterData.asset.length > 0
                ? JSON.stringify(this.filterData.asset)
                : "",
            field:
              this.filterData.field.length > 0
                ? JSON.stringify(this.filterData.field)
                : "",
            well:
              this.filterData.well.length > 0
                ? JSON.stringify(this.filterData.well)
                : "",
            reservoir:
              this.filterData.reservoir.length > 0
                ? JSON.stringify(this.filterData.reservoir)
                : "",
            sampleType:
              this.filterData.sampleType.length > 0
                ? JSON.stringify(this.filterData.sampleType)
                : "",
          },
    });
    if (response.data && response.data.data) {
      const data = response.data.data;
      this.assetData = data?.filter(item => item.scope === "asset");
      this.fieldData = data?.filter(item => item.scope === "field");
      this.reservoirData = data?.filter(item => item.scope === "reservoir");
      const companyData = data?.filter(item => item.scope === "company");
      this.totalItems = companyData[0]?.totalCorrected;
      if(this.filterData.field.length || this.filterData.reservoir.length)
      {
        this.filterData.asset=this.assetData.map(item => item.asset);
      }
      if(this.filterData.reservoir.length)
      {
        this.filterData.field=this.fieldData.map(item => item.field);
      }
      
    }
  } catch (error) {
    console.error("Error fetching metrics data:", error);
  } finally{
    this.localLoading=false;
  }
    },
  },

  // Fetch data and metrics when component is mounted
  mounted() {
    this.fetchMetrics(); // Fetch initial metrics

  },
};
</script>

<style scoped>
</style>
