<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#aiCorrectionModal"
      ref="openModalCorrection"
    ></button>

    <div
      class="modal fade"
      id="aiCorrectionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body p-0 mr-0">
            <div class="container-fluid ai-correction">
              <div class="row no-gutters">
                <div class="col-12 col-lg-8 col-xl-9 pr-3">
                  <div class="chart-content-top"></div>
                  <div class="chart-info text-center">
                    <free-style-shimmer
                      v-if="loading"
                      :is-loading="true"
                      height="530px"
                      width="100%"
                      border-radius="12px"
                      color="#F6F6F6"
                    />
                    <ClusterDataComponent
                      v-else
                      ref="sampleChartComponent"
                      @start-loading="startLoading"
                      @stop-loading="stopLoading"
                      :filterData="filterData"
                      :samples="samples"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 px-lg-3 pr-3">
                  <ImportSampleComponent
                    @start-loading="startLoading"
                    @stop-loading="stopLoading"
                    @loaded-samples="loadedSamples"
                    @close-modal="closeModal"
                    @show-dynamic-modal="showDynamicModal"
                    :requestID="requestID"
                    :selectedFile="selectedFile"
                    :asset="asset"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClusterDataComponent from "../Common/ClusterDataComponent.vue";
import ImportSampleComponent from "./ImportSampleComponent.vue";
import Plotly from "plotly.js-dist";
import { showErrorToast } from "../../services/toastService";
import $ from "jquery";

export default {
  name: "SampleCorrection",
  components: { ClusterDataComponent, ImportSampleComponent },
  emits: [
    'start-loading',
    'stop-loading',
    'show-dynamic-modal',
    'loaded-samples'
  ],
  data() {
    return {
      samples: {},
      requestID: "",
      selectedFile: "",
      asset: "",
      filterData: {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
        fluidType:[],
        isCorrected: 'both',
      },
      arrow_down: require("../../assets/images/navbar/arrow-down.png"),
      loading: true,
    };
  },
  mounted() {
    // Bind Bootstrap modal's 'shown.bs.modal' event
    const modalElement = document.getElementById("aiCorrectionModal");
    if (modalElement) {
      $(modalElement).on("shown.bs.modal", this.onModalShown);
    }
  },
  beforeDestroy() {
    // Remove the event listener to avoid memory leaks
    const modalElement = document.getElementById("aiCorrectionModal");
    if (modalElement) {
      $(modalElement).off("shown.bs.modal", this.onModalShown);
    }
  },
  methods: {
    startLoading() {
      this.$emit("start-loading");
    },
    stopLoading() {
      this.$emit("stop-loading");
    },
    loadedSamples(samples)
    {
      console.log('loaded sample',samples)
      this.samples=samples;
    },
    showDynamicModal(
      sampleData,
      sampleId,
      modalIcon,
      modalTitle,
      modalText,
      modalTextClass,
      modalButtons
    ) {
      $("#aiCorrectionModal").modal("hide");
      this.$emit(
        "show-dynamic-modal",
        sampleData,
        sampleId,
        modalIcon,
        modalTitle,
        modalText,
        modalTextClass,
        modalButtons
      );
    },
    openModal(requestID) {
      var elem = this.$refs.openModalCorrection;
      if (elem) {
        this.requestID = requestID;
        this.loading= true;
        elem.click();
      }
    },

    openModalWithFile(selectedFile, asset) {
      var elem = this.$refs.openModalCorrection;
      if (elem) {
        this.selectedFile = selectedFile;
        this.asset = asset;
        this.loading= true;
        elem.click();
      }
    },
    closeModal() {
      $("#aiCorrectionModal").modal("hide");
          showErrorToast("Internal Error,  Please try again.");
    },
    onModalShown() {
      if (
        this.$refs.sampleChartComponent &&
        this.$refs.sampleChartComponent.plotContainer
      ) {
        const plotEl = this.$refs.sampleChartComponent.plotContainer;
        Plotly.Plots.resize(plotEl);
        this.loading = false;
      }
      else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
};
</script>