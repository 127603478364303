<template>
  <div class="system-managment-sec">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation" v-if="!isPermissionLoading && hasPermission('ManageUsers')">
        <button class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button"
          role="tab" aria-controls="pills-home" aria-selected="true">
          Manage User
        </button>
      </li>
      <li class="nav-item" role="presentation" v-if="!isPermissionLoading && hasPermission('ManageRoles')">
        <button class="nav-link" :class="{ 'active': !hasPermission('ManageUsers') }" 
          id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" type="button"
          role="tab" aria-controls="pills-profile" aria-selected="false">
          Manage Roles
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div 
        class="tab-pane fade" 
        :class="{ 'show active': !isPermissionLoading && hasPermission('ManageUsers') }" 
        id="pills-home" 
        role="tabpanel" 
        aria-labelledby="pills-home-tab" 
        v-if="!isPermissionLoading && hasPermission('ManageUsers')"
      >
        <AddUserComponent @userAdded="handleUserAdded" />
        <UserTableComponent ref="userTable" :users="users" />
      </div>
      <div 
        class="tab-pane fade" 
        :class="{ 'show active': !isPermissionLoading && !hasPermission('ManageUsers') && hasPermission('ManageRoles') }"
        id="pills-profile" 
        role="tabpanel" 
        aria-labelledby="pills-profile-tab" 
        v-if="!isPermissionLoading && hasPermission('ManageRoles')"
      >
        <AddRoleComponent @roleAdded="handleRoleAdded" :permissions="permissions" />
        <RolesTableComponent ref="roleTable" :roles="roles" :permissions="permissions" />
      </div>
    </div>
  </div>
</template>

<script>
import AddRoleComponent from './AddRoleComponent.vue';
import AddUserComponent from './AddUserComponent.vue';
import RolesTableComponent from './RolesTableComponent.vue';
import UserTableComponent from './UserTableComponent.vue';
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { AddUserComponent, AddRoleComponent, UserTableComponent, RolesTableComponent },
  name: "ManagementTabsComponent",
  data() {
    return {
      users: [],
      roles: [],
      permissions: [],
      searchQuery: '',
      selectedRole: '',
    };
  },
  computed: {
    ...mapState({
      isPermissionLoading: state => state.auth.isPermissionLoading
    }),
    ...mapGetters(['hasPermission']),
  },
  methods: {
    async fetchPermissions() {
      try {
        const response = await axios.get('/permissions');
        this.permissions = response.data.data.map(permission => permission.name);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        this.permissions = [];
      }
    },
    handleUserAdded() {
      this.$refs.userTable.fetchUsers();  // Refresh the table after new user is added
    },
     handleRoleAdded() {
      this.$refs.roleTable.fetchRoles();  // Refresh the table after new user is added
    }
  },
  mounted() {
    this.fetchPermissions();
  },
};
</script>
