import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import axios from "axios";
import Vue3Select from 'vue3-select'
import Shimmer from 'vue3-shimmer';

import EventBus from './utils/eventBus';

import 'vue3-select/dist/vue3-select.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./assets/styling/style.css";
import "./assets/styling/responsive.css"
import '@fortawesome/fontawesome-free/css/all.css'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// Set the Axios default base URL
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Axios interceptor to handle 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    // if (!error.response) {
    //   // Handle network error or CORS error
    //   if (error.message.includes('Network Error') || error.message.includes('CORS')) {
    //     store.dispatch('logout');
    //     router.push('/404');
    //     EventBus.emit('network-error', error.message);
    //   }
    // } else if (error.response.status === 401 || error.code=="ERR_NETWORK") {
    //   // If the status is 401 and no response body is returned
    //   store.dispatch('logout'); // Clear auth data
    //   router.push('/404'); // Redirect to 404 page
    // }
    return Promise.reject(error);
  }
);
const app = createApp(App);

app.use(store);
app.use(router);
app.use(Shimmer);
app.use(Toast);
app.component('vue3-select', Vue3Select);
app.mount('#app');
