<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#Success"
      ref="openModalSuccess"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="Success">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <img src="../../assets/images/userRoles/success.png" alt="success" />
            <p>{{ successMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModalComponent",
  props: {
    successMessage: {
      type: String,
      default: 'Operation Successful'
    },
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalSuccess;
      if (elem) {
        elem.click();
      }
    },
  },
};
</script>
