<template>
  <div>
    <SaveFilterModalComponent
      ref="filterSaveModal"
      @reopenParentModal="reopenParentModal"
      @fetch-apply-filter="fetchAndApplyFilter" 
    />
    <DeleteFilterModalComponent
      ref="filterDeleteModal"
      @reopenParentModal="reopenParentModal"
      @fetch-reset-filter="fetchAndResetFilter" 
    />
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FilterData"
      ref="openModalFilterData"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="FilterData">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-top">
              <h5 class="modal-title" id="staticBackdropLabel">Data Filters</h5>
              <div>
                <!-- <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  v-if="selectedSavedOption === null"
                  @click="showFilterSaveModal"
                >
                  <div aria-hidden="true">
                    <img :src="save" alt="save" />
                    <span>Save</span>
                  </div>
                </button> -->
                <button type="button" @click="resetFilter">
                  <div aria-hidden="true">
                    <img :src="reset" alt="reset" />
                    <span>New Filter</span>
                  </div>
                </button>
              </div>
            </div>
            <div class="row no-gutters w-100 saved-filter-option">
              <div class="col">
                <vue3-select
                  :options="savedFilterOptions"
                  v-model="selectedSavedOption"
                  placeholder="Select a saved filter"
                >
                  <template #option="option">
                    <div
                      class="custom-option d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <div class="filter-title">{{ option.label }}</div>

                        <div class="filter-details">{{ option.details }}</div>
                      </div>
                      <div class="filter-icons d-flex">
                        <i
                          :class="{
                            'fas fa-star': option.star,
                            'far fa-star': !option.star,
                          }"
                          @click.stop="defaultFilter(option)"
                        ></i>
                        <div class="delete-filter" @click.stop="deleteFilter(option)">
                        <i
                          class="far fa-trash-can "
                        >
                        </i>
                        <span>Delete</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #selected-option="selectedSavedOption">
                    <div>
                      <div class="filter-title">{{ selectedSavedOption.label }}</div>
                      <div class="filter-details">{{ selectedSavedOption.details }}</div>
                    </div>
                  </template>
                </vue3-select>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <h5>Custom Filters</h5>
            <form>
              <div class="row no-gutters"  v-if="selectedSavedOption !== null">
                <div class="col">
                  <label for="asset">Name</label>
                    <input v-model="name" class="form-control" type="text" placeholder="Enter your filter name">
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col">
                  <label for="asset">Asset</label>
                  <vue3-select
                    multiple
                    v-model="asset"
                    :options="filteredOptionsAssets"
                    :close-on-select="false"
                    placeholder="Select an Asset"
                    @update:modelValue="fetchFilterOptions"
                  />
                </div>
                <div class="col">
                  <label for="field">Field</label>
                  <vue3-select
                    multiple
                    v-model="field"
                    :options="filteredOptionsFields"
                    :close-on-select="false"
                    placeholder="Select a Field"
                    @update:modelValue="fetchFilterOptions"
                  />
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col">
                  <label for="well">Well</label>
                  <vue3-select
                    multiple
                    v-model="well"
                    :options="filteredOptionsWells"
                    :close-on-select="false"
                    placeholder="Select a Well"
                    @update:modelValue="fetchFilterOptions"
                    class="select-upwards"
                  />
                </div>
                <div class="col">
                  <label for="sample">Sample</label>
                  <vue3-select
                    multiple
                    v-model="sample"
                    :options="filteredOptionsSamples"
                    :close-on-select="false"
                    placeholder="Select a Sample"
                    @update:modelValue="fetchFilterOptions"
                    class="select-upwards"
                  />
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col">
                  <label for="reservoir">Sample Type</label>
                  <vue3-select
                    multiple
                    v-model="sampleType"
                    :options="sampleTypes"
                    placeholder="Select a Sample Type"
                    class="select-upwards"
                  />
                </div>
                <div class="col">
                  <label for="reservoir">Reservoir</label>
                  <vue3-select
                    multiple
                    v-model="reservoir"
                    :options="filteredOptionsReservoirs"
                    :close-on-select="false"
                    placeholder="Select a Reservoir"
                    class="select-upwards"
                  />
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col">
                  <label for="ai-corrected">AI Corrected ?</label>
                  <vue3-select
                    v-model="ai_corrected"
                    :options="isCorrected"
                    placeholder="Select AI Corrected"
                    class="select-upwards"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" @click="applyFilter">Apply</button>
            <button type="button" class="btn" data-dismiss="modal" aria-label="Close" @click="showFilterSaveModal" v-if="selectedSavedOption === null">Save</button>
            <button type="button" class="btn" v-else @click="updateFilter(false)" :disabled="loading">
              <span v-if="loading">
                <span>Updating...</span>
              </span>
              <span v-else class="export-sec">
                <span>Update</span>
              </span>
            </button>
            <button type="button" class="btn cancel" data-dismiss="modal" aria-label="Close">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import $ from "jquery";
import SaveFilterModalComponent from "./SaveFilterModalComponent.vue";
import DeleteFilterModalComponent from "./DeleteFilterModalComponent.vue";

export default {
  name: "FilterDataModalComponent",
  components: {
    SaveFilterModalComponent,
    DeleteFilterModalComponent,
  },
  data() {
    return {
      save: require("../../../assets/images/modal/disk 1.png"),
      reset: require("../../../assets/images/modal/refresh-2.png"),
      name:'',
      asset: [],
      filterData:{},
      assets: [],
      field: [],
      fields: [],
      well: [],
      wells: [],
      sample: [],
      samples: [],
      fluidType:[],
      sampleType:[],
      sampleTypes:[],
      reservoir: [],
      reservoirs: [],
      ai_corrected: null,
      isCorrected: ['Yes','No'],
      selectedSavedOption: null,
      savedFilterOptions: [],
      applyDefault:true,
      loading: false,
    };
  },
  watch: {
    selectedSavedOption(newVal) {
      if (newVal) {
        this.name = (newVal.raw.name)?newVal.raw.name:'';
        this.asset = (newVal.raw.asset)?newVal.raw.asset:[];
        this.field = (newVal.raw.field)?newVal.raw.field:[];
        this.well = (newVal.raw.well)?newVal.raw.well:[];
        this.reservoir = (newVal.raw.reservoir)?newVal.raw.reservoir:[];
        this.sampleType = (newVal.raw.sampleType)?newVal.raw.sampleType:[];
        this.sample = (newVal.raw.sampleName)?newVal.raw.sampleName:[];
        this.ai_corrected = (newVal.raw.isCorrected)?(newVal.raw.isCorrected==true?'Yes':'No'):null;
      } else {
        this.name='';
        this.asset = [];
        this.field = [];
        this.well = [];
        this.reservoir = [];
        this.sample = [];
        this.sampleType = [];
        this.ai_corrected=null;
        this.filterData= {
          asset: [],
          field: [],
          reservoir:[],
          well: [],
          sample:[],
          sampleType:[],
          fluidType:[],
          isCorrected:'',
          isAnchorPoint:null,
          filterType:null,
        };
      }
      this.fetchFilterOptions();
    }
  },
   computed: {
    filteredOptionsAssets() {
      return this.assets.filter(
        (option) => !this.asset.includes(option)
      );
    },
     filteredOptionsFields() {
      return this.fields.filter(
        (option) => !this.field.includes(option)
      );
    },
     filteredOptionsWells() {
      return this.wells.filter(
        (option) => !this.well.includes(option)
      );
    },
     filteredOptionsSamples() {
      return this.samples.filter(
        (option) => !this.sample.includes(option)
      );
    },
     filteredOptionsReservoirs() {
      return this.reservoirs.filter(
        (option) => !this.reservoir.includes(option)
      );
    },
  },
  methods: {
    showFilterSaveModal() {
      this.filterData= {
            asset: this.asset,
            field: this.field,
            reservoir:this.reservoir,
            well: this.well,
            sample:this.sample,
            sampleType:this.sampleType,
            fluidType:this.fluidType,
            isAnchorPoint:null,
            isCorrected:this.ai_corrected?(this.ai_corrected=='Yes'?true:false):'',
            filterType:1,
        };
      this.$refs.filterSaveModal.openModal(this.filterData);
    },
    openModal() {
      var elem = this.$refs.openModalFilterData;
      if (elem) {
        elem.click();
      }
    },
    reopenParentModal() {
      $("#FilterData").modal("show");
    },
    defaultFilter(option) {
      option.star=(option.star == false)?true: false;
      this.selectedSavedOption=option;
      this.name = (option.raw.name)?option.raw.name:'';
      this.asset = (option.raw.asset)?option.raw.asset:[];
      this.field = (option.raw.field)?option.raw.field:[];
      this.well = (option.raw.well)?option.raw.well:[];
      this.reservoir = (option.raw.reservoir)?option.raw.reservoir:[];
      this.sampleType = (option.raw.sampleType)?option.raw.sampleType:[];
      this.sample = (option.raw.sampleName)?option.raw.sampleName:[];
      this.ai_corrected = (option.raw.isCorrected)?(option.raw.isCorrected==true?'Yes':'No'):null;
      this.updateFilter(true);
      if(option.star==false)
      {
        this.resetFilter();
      }
    },
    updateFilter(applyDefault) {
      this.loading =true
      if (this.selectedSavedOption) {
        const filterData = {
          Data: {
            Filter: {
              name: this.name,
              asset: this.asset,
              field: this.field,
              well: this.well,
              reservoir: this.reservoir,
              sampletype: this.sampleType,
              fluidType:this.fluidType,
              sampleName: this.sample,
              isDefault: this.selectedSavedOption.star,
              isCorrected: this.ai_corrected?(this.ai_corrected=='Yes'?true:false):null,
              isAnchorPoint:null,
            }
          }
        };

        axios.put(`filters/${this.selectedSavedOption.id}`, filterData)
          .then(response => {
            this.applyDefault=applyDefault;
            this.fetchSavedFilter();
            this.applyFilter();
            $("#FilterData").modal("hide");
          })
          .catch(error => {
            console.error('Error updating filter:', error);
          })
          .finally(() => {
            this.loading = false
          });
      }
    },
    deleteFilter(option) {
      $("#FilterData").modal("hide");
      this.$refs.filterDeleteModal.openModal(option);
    },
    resetFilter()
    {
      this.name='';
      this.asset = [];
      this.field = [];
      this.well = [];
      this.reservoir = [];
      this.sample = [];
      this.sampleType = [];
      this.ai_corrected=null;
      this.selectedSavedOption=null;
      this.filterData= {
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        fluidType:[],
        isCorrected:null,
        isAnchorPoint:null,
        filterType:null,
      };
      //  $("#FilterData").modal("hide");
      // this.$emit("apply-filter", this.filterData);
    },
    resetFilterWithoutCloseModal()
    {
      this.name='';
      this.asset = [];
      this.field = [];
      this.well = [];
      this.reservoir = [];
      this.sample = [];
      this.sampleType = [];
      this.ai_corrected=null;
      this.selectedSavedOption=null;
      this.filterData= {
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        fluidType:[],
        isCorrected:null,
        isAnchorPoint:null,
        filterType:null,
      };
      this.$emit("apply-filter", this.filterData);
    },
    fetchAndResetFilter()
    {
      this.resetFilterWithoutCloseModal();
      this.applyDefault=false;
      this.fetchSavedFilter();
    },
    fetchAndApplyFilter()
    {
      this.applyFilter();
      this.applyDefault=false;
      this.fetchSavedFilter();
    },
    applyFilter()
    {
      this.filterData= {
            asset: this.asset,
            field: this.field,
            reservoir:this.reservoir,
            well: this.well,
            sample:this.sample,
            sampleType:this.sampleType,
            fluidType:this.fluidType,
            isCorrected:this.ai_corrected?(this.ai_corrected=='Yes'?true:false):'',
            isAnchorPoint:null,
            filterType:1,
        };
      $("#FilterData").modal("hide");
      this.$emit("apply-filter", this.filterData);
    },
    fetchFilterOptions() {
      axios.get('filter/options',{
          params: {
            asset:(this.asset.length>0)?JSON.stringify(this.asset):'',
            field:(this.field.length>0)?JSON.stringify(this.field):'',
            well:(this.well.length>0)?JSON.stringify(this.well):'',
          }
        })
        .then(response => {
          const data = response.data.data;
          this.assets = (data.locations.asset)?data.locations.asset:[];
          this.fields = (data.locations.field)?data.locations.field:[];
          this.wells = (data.locations.well)?data.locations.well:[];
          this.reservoirs = (data.locations.reservoir)?data.locations.reservoir:[];
          this.sampleTypes = (data.sampleTypes)?data.sampleTypes:[];
          this.samples = (data.samples)?data.samples.name.map(name => name):[];
        })
        .catch(error => {
          console.error('Error fetching filter options:', error);
        });
    },
    fetchSavedFilter() {
      // this.selectedSavedOption=null;
      axios.get('filters')
        .then(response => {
          const data = response.data.data;
          this.savedFilterOptions = data.map(filter => (
            {
            label: filter.name,
            details: `Asset: ${filter.asset?.join(', ') ?? ''} | Field: ${filter.field?.join(', ') ?? ''} | Well: ${filter.well?.join(', ') ?? ''} | Sample Type: ${filter.sampleType?.join(', ') ?? ''}`,
            id: filter.id,
            star: filter.isDefault,
            raw: filter,
          }));
          const defaultFilter = this.savedFilterOptions.find(option => option.star);
          if (defaultFilter && this.applyDefault==true) {
            this.selectedSavedOption = defaultFilter;
            this.name = (defaultFilter.raw.name)?defaultFilter.raw.name:'';
            this.asset = (defaultFilter.raw.asset)?defaultFilter.raw.asset:[];
            this.field = (defaultFilter.raw.field)?defaultFilter.raw.field:[];
            this.well = (defaultFilter.raw.well)?defaultFilter.raw.well:[];
            this.reservoir = (defaultFilter.raw.reservoir)?defaultFilter.raw.reservoir:[];
            this.sampleType = (defaultFilter.raw.sampleType)?defaultFilter.raw.sampleType:[];
            this.sample = (defaultFilter.raw.sampleName)?defaultFilter.raw.sampleName:[];
            this.ai_corrected = (defaultFilter.raw.isCorrected)?(defaultFilter.raw.isCorrected==true?'Yes':'No'):null;
            this.applyFilter();
          }
        
        })
        .catch(error => {
          console.error('Error fetching filter options:', error);
        });
    },
  },
  mounted() {
    this.fetchFilterOptions();
    this.fetchSavedFilter();
  },
};
</script>
