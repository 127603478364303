<template>
  <div>
    <SuccessModalComponent ref="successModal" successMessage="User Added Successfully" />
    <ErrorModalComponent ref="errorModal" errorMessage="Failed to add new User" />
    <div class="add-sec">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <h1>Add Users</h1>
          </div>
          <div class="col-12 col-md-9">
            <form>
              <div class="row">
                <div class="col-12 col-md-4">
                  <label for="email">User Email Address</label>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="Enter User Email"
                    :class="{ 'is-invalid': !isValidEmail && email }"
                  />
                  <div class="invalid-feedback" v-if="!isValidEmail && email">
                    Please enter a valid email address
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-3 mt-md-0 pl-md-0">
                  <label for="Roles">Select Roles</label>
                  <vue3-select
                    v-model="select_roles"
                    :options="roles"
                    placeholder="Select Roles"
                    :loading="isRolesLoading"
                    :disabled="isRolesLoading || roles.length === 0"
                    label="name"   
                    value="id"      
                  />
                </div>
                <div class="col-12 col-md-4 mt-3 mt-md-0 pl-md-0">
                  <label for="usertype">Select User Type</label>
                  <vue3-select
                    v-model="select_usertype"
                    :options="usertypes"
                  />
                </div>
                
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-end">
              <button
                type="button"
                class="btn main-btn"
                :disabled="isLoading || !email || !select_roles || !select_usertype || !isValidEmail"
                @click="addUser"
              >
                <span v-if="isLoading">Adding...</span>
                <div v-else>
                  <img
                    src="../../assets/images/userRoles/add-circle.png"
                    alt="Add"
                  />
                  <span>Add User</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessModalComponent from './SuccessModalComponent.vue';
import axios from 'axios';
import ErrorModalComponent from './ErrorModalComponent.vue';

export default {
  components: { SuccessModalComponent, ErrorModalComponent },
  name: "AddUserComponent",
  data() {
    return {
      email: null,
      select_roles: null,
      select_usertype: 'sso',
      roles: [], // Initialize roles as an empty array
      usertypes: ['sso'], 
      isLoading: false,
      isRolesLoading: false, // Loader for fetching roles
    };
  },
  computed: {
    isValidEmail() {
      if (!this.email) return true; // Don't show error when empty
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    }
  },
  mounted() {
    this.fetchRoles(); // Fetch roles when the component is mounted
  },
  methods: {
    async fetchRoles() {
      this.isRolesLoading = true;
      try {
        const response = await axios.get('/roles'); // Make API call to get roles
        this.roles = response.data.data || []; // Update to use response.data.data
      } catch (error) {
        console.error("Error fetching roles:", error.response || error.message);
        // Handle error appropriately (optional)
      } finally {
        this.isRolesLoading = false;
      }
    },
    async addUser() {
      if (!this.isValidEmail) {
        return;
      }

      // Prepare the user data
      const userData = {
        data: {
          user: {
            role: this.select_roles.name,
            username: this.email,
            usertype: this.select_usertype,
          },
        },
      };

      this.isLoading = true;

      try {
        // Make the POST request to add the user
        const response = await axios.post('/users', userData);
        if(response.data.statusCode==200)
        {
           // Show success modal on successful response
            // Dispatch a custom event that UserTableComponent is listening for
            const event = new CustomEvent('userData', { detail: response.data.data });
            window.dispatchEvent(event);  
              // Reset the form fields
            this.email = null;
            this.select_roles = null;
            this.$emit('userAdded', response.data);
            setTimeout(() => {
              this.$refs.successModal.openModal();
            }, 1000);
        }
        else
        {
          const message = response.data.message;
          this.$refs.errorModal.openModal(message);
        }
      
      } catch (error) {
        console.error("Error adding user:", error.response || error.message);
        // Handle error appropriately
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
