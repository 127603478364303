<template>
  <div>
    <OneDExpandComponent ref="oneDChartExpand" :expandedChartData="chartData" />
    <div class="dashboard-inventory-analysis">
      <h6 class="mb-3">Inventory Analysis</h6>
      <free-style-shimmer v-if="loading && !isAnyChartLoaded" :is-loading="true" height="880px" width="100%"
        border-radius="12px" color="#F6F6F6" />

      <div v-else class="charts-container row no-gutters align-items-center">
        <div v-for="(data, index) in chartData" :key="index"
          class="chart-item col-6 col-sm-4 col-md-4 col-lg-6 col-xl-4">
          <iframe v-if="data.html" :srcdoc="data.html" style="border: 0; overflow: hidden;" scrolling="no" @load="onIframeLoad"></iframe>
          <div class="chart-controls" v-if="data.csv && iframeLoaded">
            <button type="button" class="btn download-btn" @click="downloadCSV(data.csv, index)" title="Download CSV">
              <img :src="downloadIcon" alt="download" />
            </button>
          </div>
        </div>
        <button type="button" class="btn expand-btn custs-BTN" @click="showModal">
          <img :src="expand" alt="expand" />
          <span>Expand</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OneDExpandComponent from "@/components/Common/OneDExpandComponent.vue";
import axios from "axios";
import Vue from 'vue';

export default {
  name: "DashboardInventoryAnalysisComponent",
  components: { OneDExpandComponent },
  props: {
    chartProperties: {
      type: Array,
      required: false,
      default: () => []
    },
    filterData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      expand: require("../../../assets/images/table/expand.png"),
      downloadIcon: require("../../../assets/images/icons/csv-download.png"),
      loading: true,
      chartData: [],
      iframeLoaded: false
    };
  },
  computed: {
    isAnyChartLoaded() {
      return this.chartData.some((data) => data.html !== null);
    },
  },
  methods: {
    onIframeLoad() {
      this.iframeLoaded = true;
    },
    showModal() {
      this.$refs.oneDChartExpand?.openModal();
    },
    async fetchChartProperties() {
      if (!this.chartProperties?.length) return;
      
      try {
        const currentLength = this.chartProperties.length;
        const requiredLength = 18;
        
        // Pre-calculate repeated properties array
        const repeatedProperties = Array(requiredLength).fill(null).map((_, index) => 
          this.chartProperties[index % currentLength]
        );
        
        this.chartData = new Array(requiredLength).fill({ html: null, csv: null });
        await this.fetchChartData(repeatedProperties);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    },
    async fetchChartData(properties) {
      this.loading = true;
      this.iframeLoaded = false;

      try {
        // Batch API calls in groups of 6 to prevent overwhelming the server
        const batchSize = 6;
        const batches = [];
        
        for (let i = 0; i < properties.length; i += batchSize) {
          const batch = properties.slice(i, i + batchSize).map((property, batchIndex) => {
            if (!property) return Promise.resolve({ html: null, csv: null });
            
            // Add filter parameters to URL
            const filterParams = {
              asset: this.filterData?.asset ? this.filterData.asset.join(',') : "",
              field: this.filterData?.field ? this.filterData.field.join(',') : "",
              reservoir: this.filterData?.reservoir ? this.filterData.reservoir.join(',') : "",
              well: this.filterData?.well ? this.filterData.well.join(',') : "",
              sampleType: this.filterData?.sampleType ? this.filterData.sampleType.join(',') : ""
            };

            const url = `/dataapi/dashboard/1dHistograms?property="${property.name}"&width=170&height=110&asset="${filterParams.asset}"&field="${filterParams.field}"&reservoir="${filterParams.reservoir}"&well="${filterParams.well}"&sampleType="${filterParams.sampleType}"`;
            
            return axios.post(url)
              .then(response => {
                const data = response.data?.data?.response;
                if (data) {
                  this.chartData[i + batchIndex] = {
                    html: data.html,
                    csv: data.chartData
                  };
                  return { html: data.html, csv: data.chartData };
                }
                return { html: null, csv: null };
              })
              .catch(error => {
                console.error("Error fetching chart data:", error);
                return { html: null, csv: null };
              });
          });
          
          batches.push(Promise.all(batch));
        }

        await Promise.all(batches);
      } finally {
        this.loading = false;
      }
    },
    downloadCSV(csvData, index) {
      if (!csvData?.length) return;

      const csvContent = this.convertToCSV(csvData);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      
      try {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `chart_data_${csvData[0].property}.csv`);
        document.body.appendChild(link);
        link.click();
      } finally {
        setTimeout(() => URL.revokeObjectURL(url), 100);
      }
    },
    convertToCSV(data) {
      if (!data?.length) return "";

      const keys = Object.keys(data[0]);
      const header = keys.map(key => 
        key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase())
      );

      const rows = data.map(row =>
        keys.map(key => {
          const value = row[key];
          return typeof value === "number" ? value.toFixed(3) : value;
        }).join(",")
      );

      return `${header.join(",")}\n${rows.join("\n")}`;
    }
  },
  watch: {
    chartProperties: {
      immediate: true,
      handler(newVal) {
        if (newVal?.length) {
          this.fetchChartProperties();
        }
      },
    },
    filterData: {
      deep: true,
      handler() {
        if (this.chartProperties?.length) {
          this.fetchChartProperties();
        }
      }
    }
  },
};
</script>

<style scoped>
.chart-item {
  position: relative;
}

.chart-item iframe {
  width: 190px;
  height: 120px;
  margin-bottom: 5px;
}

.chart-controls {
  position: absolute;
  top: -5px;
  right: -20px;
}

.download-btn img {
  width: 20px;
  height: 20px;
}
</style>
