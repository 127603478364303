export function getFilteredData(filterCriteria, originalSampleData, sampleData, switchMole) {


  // -------- Functions
  const filterCompositions = (composition, dataKey) => {
      return composition?.map(composit => ({
        component: composit.component,
        mw: composit.mw,
        mw_UOM: composit.mw_UOM,
        [dataKey]: composit[dataKey], 
      })).filter(item => item[dataKey] !== null);
  };
  
  const mergeCompositions = (targetCompositions, sourceCompositions) => {
    sourceCompositions?.forEach(newComp => {
      const existingComp = targetCompositions.find(comp => comp.component === newComp.component);
      if (existingComp) {
        Object.assign(existingComp, newComp);
      } else {
        targetCompositions.push(newComp);
      }
    });
  };

  const sortCompositionsByOriginalOrder = (targetCompositions, originalCompositions) => {
    const componentOrder = originalCompositions?.map(comp => comp.component);
    targetCompositions.sort((a, b) => componentOrder.indexOf(a.component) - componentOrder.indexOf(b.component));
  };

  // ---------------------------------------------------

  const glossaryMap = {
    flashed_gas: !switchMole ? 'stockTankGas_MoleFraction' : 'stockTankGas_MassFraction',
    sto_corrected_composition: !switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction',
    sto_uncorrected_composition: !switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction',
    separator_liquid_corrected: !switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction',
    separator_liquid_uncorrected: !switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction',
    separator_gas: !switchMole ? 'separatorGas_MoleFraction' : 'separatorGas_MassFraction',
    reservoir_fluid_corrected: !switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction',
    reservoir_fluid_uncorrected: !switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction',
  };
  let anyFilterApplied = false;

  const newSampleData = {
    correctedComposition: { composition: [] },
    originalComposition: { composition: [] },
  };

  // Apply filters based on filterCriteria
  Object.keys(glossaryMap).forEach(key => {
    if (filterCriteria[key]) {
      const dataKey = glossaryMap[key];
      mergeCompositions(newSampleData.correctedComposition?.composition, filterCompositions(originalSampleData?.correctedComposition?.composition, dataKey));
      mergeCompositions(newSampleData.originalComposition?.composition, filterCompositions(originalSampleData?.originalComposition?.composition, dataKey));
      anyFilterApplied = true;
    }
  });

  // If no filters are applied, return an empty dataset
  if (!anyFilterApplied) {
    return {
      correctedComposition: { composition: [] },
      originalComposition: { composition: [] },
    };
  } else {
    sortCompositionsByOriginalOrder(newSampleData?.correctedComposition?.composition, sampleData?.correctedComposition?.composition);
    sortCompositionsByOriginalOrder(newSampleData?.originalComposition?.composition, sampleData?.originalComposition?.composition);

   // Handling for stockTankOil fractions
    if (!filterCriteria.sto_corrected_composition) {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp['stockTankOil_MoleFraction'];
        delete comp['stockTankOil_MassFraction'];
        delete comp['stockTankOil_MoleFraction_UOM'];
        delete comp['stockTankOil_MassFraction_UOM'];
      });
      
    } else {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction'];
        delete comp[switchMole ? 'stockTankOil_MoleFraction_UOM' : 'stockTankOil_MassFraction_UOM'];
      });
    }

    if (!filterCriteria.sto_uncorrected_composition) {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp['stockTankOil_MoleFraction'];
        delete comp['stockTankOil_MassFraction'];
        delete comp['stockTankOil_MoleFraction_UOM'];
        delete comp['stockTankOil_MassFraction_UOM'];
      });
      
    } else {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'stockTankOil_MoleFraction' : 'stockTankOil_MassFraction'];
        delete comp[switchMole ? 'stockTankOil_MoleFraction_UOM' : 'stockTankOil_MassFraction_UOM'];
      });
    }

    // Handling for separatorLiquid fractions
    if (!filterCriteria.separator_liquid_corrected) {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp['separatorLiquid_MoleFraction'];
        delete comp['separatorLiquid_MassFraction'];
        delete comp['separatorLiquid_MoleFraction_UOM'];
        delete comp['separatorLiquid_MassFraction_UOM'];
      });
    } else {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction'];
        delete comp[switchMole ? 'separatorLiquid_MoleFraction_UOM' : 'separatorLiquid_MassFraction_UOM'];
      });
    }

    if (!filterCriteria.separator_liquid_uncorrected) {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp['separatorLiquid_MoleFraction'];
        delete comp['separatorLiquid_MassFraction'];
        delete comp['separatorLiquid_MoleFraction_UOM'];
        delete comp['separatorLiquid_MassFraction_UOM'];
      });
    } else {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'separatorLiquid_MoleFraction' : 'separatorLiquid_MassFraction'];
        delete comp[switchMole ? 'separatorLiquid_MoleFraction_UOM' : 'separatorLiquid_MassFraction_UOM'];
      });
    }

    // Handling for reservoirLiquid fractions
    if (!filterCriteria.reservoir_fluid_corrected) {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp['reservoirLiquid_MoleFraction'];
        delete comp['reservoirLiquid_MassFraction'];
        delete comp['reservoirLiquid_MoleFraction_UOM'];
        delete comp['reservoirLiquid_MassFraction_UOM'];
      });
    } else {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction'];
        delete comp[switchMole ? 'reservoirLiquid_MoleFraction_UOM' : 'reservoirLiquid_MassFraction_UOM'];

      });
    }

    if (!filterCriteria.reservoir_fluid_uncorrected) {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp['reservoirLiquid_MoleFraction'];
        delete comp['reservoirLiquid_MassFraction'];
        delete comp['reservoirLiquid_MoleFraction_UOM'];
        delete comp['reservoirLiquid_MassFraction_UOM'];
      });
    } else {
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'reservoirLiquid_MoleFraction' : 'reservoirLiquid_MassFraction'];
        delete comp[switchMole ? 'reservoirLiquid_MoleFraction_UOM' : 'reservoirLiquid_MassFraction_UOM'];
      });
    }

    // Handling for flashed gas
    if (!filterCriteria.flashed_gas) {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp['stockTankGas_MoleFraction'];
        delete comp['stockTankGas_MassFraction'];
        delete comp['stockTankGas_MoleFraction_UOM'];
        delete comp['stockTankGas_MassFraction_UOM'];
      });
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp['stockTankGas_MoleFraction'];
        delete comp['stockTankGas_MassFraction'];
        delete comp['stockTankGas_MoleFraction_UOM'];
        delete comp['stockTankGas_MassFraction_UOM'];
      });
    } else {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'stockTankGas_MoleFraction' : 'stockTankGas_MassFraction'];
        delete comp[switchMole ? 'stockTankGas_MoleFraction_UOM' : 'stockTankGas_MassFraction_UOM'];
      });
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'stockTankGas_MoleFraction' : 'stockTankGas_MassFraction'];
        delete comp[switchMole ? 'stockTankGas_MoleFraction_UOM' : 'stockTankGas_MassFraction_UOM'];
      });
    }

    // Handling for separator gas
    if (!filterCriteria.separator_gas) {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp['separatorGas_MoleFraction'];
        delete comp['separatorGas_MassFraction'];
        delete comp['separatorGas_MoleFraction_UOM'];
        delete comp['separatorGas_MassFraction_UOM'];
      });
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp['separatorGas_MoleFraction'];
        delete comp['separatorGas_MassFraction'];
        delete comp['separatorGas_MoleFraction_UOM'];
        delete comp['separatorGas_MassFraction_UOM'];
      });
    } else {
      newSampleData?.correctedComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'separatorGas_MoleFraction' : 'separatorGas_MassFraction'];
        delete comp[switchMole ? 'separatorGas_MoleFraction_UOM' : 'separatorGas_MassFraction_UOM'];

      });
      newSampleData?.originalComposition?.composition.forEach(comp => {
        delete comp[switchMole ? 'separatorGas_MoleFraction' : 'separatorGas_MassFraction'];
        delete comp[switchMole ? 'separatorGas_MoleFraction_UOM' : 'separatorGas_MassFraction_UOM'];
      });
    }

    return newSampleData;
}
}
