<template>
  <div>
    <div class="table-container mb-3">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th v-for="(header, index) in computedHeaders" :key="index" :colspan="header.colspan">
              {{ header.label }}
              <span v-if="header.unitKey">({{ header.unitKey }})</span>
            </th>
          </tr>
          <tr class="units-row">
            <th v-for="(subHeader, index) in computedSubHeaders" :key="index">{{ subHeader }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index" :class="{ 'even-row': index % 2 === 1 }">
            <td>{{ item.component }}</td>
            <td>{{ item.mw }}</td>
            <td>{{ item.density }}</td>
            <td>{{ item.flashedGas.uncorrected }}</td>
            <td v-html="getCellContent( item.component ,item.flashedGas)"></td>
            <td>{{ item.flashedLiquid.uncorrected }}</td>
            <td v-html="getCellContent(item.component ,item.flashedLiquid)"></td>
            <td>{{ item.overall.uncorrected }}</td>
            <td v-html="getCellContent(item.component ,item.overall)"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="table-container">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th v-for="key in keyData" :key="key">{{ key }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="key in keyData">{{ sampleData[key] || 'N/A' }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <div class="mb-3 overflow-hidden">
      <table class="table" style="width: 101.33%">
        <thead>
          <tr class="header-row">
            <th>Parameter Correction</th>
            <th>Uncorrected</th>
            <th>Corrected</th>'
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>STO C36+, wt%</td>
            <td>{{(sampleData?.originalComposition?.c36plus_MassFraction || 0).toFixed(3)}} </td>
            <td>{{(sampleData?.correctedComposition?.c36plus_MassFraction || 0).toFixed(3)}} </td>
          </tr>
          <tr>
            <td>STO C36+ Density, g/cc</td>
            <td>{{(sampleData?.originalComposition?.c36plus_Density || 0).toFixed(3)}} </td>
            <td>{{(sampleData?.correctedComposition?.c36plus_Density || 0).toFixed(3)}} </td>
          </tr>
          <tr>
            <td>STO C36+MW, g/mol</td>
            <td>{{(sampleData?.originalComposition?.c36plus_MW || 0).toFixed(1)}} </td>
            <td>{{(sampleData?.correctedComposition?.c36plus_MW || 0).toFixed(1)}} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row no-gutters">
      
      <div class="col-12 col-lg-6 col-xl-4" v-for="key in keyData" :key="key">
        <div class="d-flex align-items-center mb-2">
          <div class="text-capitalize key-data">{{ key }}:</div>
          <div class="value-data">{{ sampleData[key] || 'N/A' }}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ModalTableComponent",
  props: {
    isCheckedPercentage: Boolean,
    isChecked: Boolean,
    sampleData: Object,
  },
  data() {
    return {
      headers: [
        { label: "Component", colspan: 1 },
        { label: "MW", colspan: 1 },
        { label: "Density", colspan: 1 },
        { label: "Flashed Gas", colspan: 2 },
        { label: "Flashed Liquid", colspan: 2 },
        { label: "Overall", colspan: 2 },
      ],
      tableData: [], // Filled dynamically
    };
  },
  computed: {
    unitKey() {
      return this.isCheckedPercentage ? "Mole %" : "wt %";
    },
    computedSubHeaders() {
      return this.isChecked
        ? ["", "g/mol", "g/cc", "Uncorrected", "% Delta", "Uncorrected", "% Delta", "Uncorrected", "% Delta"]
        : ["", "g/mol", "g/cc", "Uncorrected", "Corrected", "Uncorrected", "Corrected", "Uncorrected", "Corrected"];
    },
    computedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        unitKey: ["Flashed Gas", "Flashed Liquid", "Overall"].includes(header.label) ? this.unitKey : null,
      }));
    },
    keyData() {
      const excludedKeys = ["correctedComposition", "location", "originalComposition"];
      // Extract keys from the data object to use as table headers
      return Object.keys(this.sampleData).filter(key => !excludedKeys.includes(key));
    }
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newData) {
        this.updateTableData(newData);
      },
    },
    isCheckedPercentage() {
      this.updateTableData(this.sampleData);
    },
    isChecked() {
      this.updateTableData(this.sampleData);
    },
  },
  methods: {
    updateTableData(data) {
      if (!data || !data.correctedComposition || !data.originalComposition) {
        this.tableData = [];
        return;
      }

      const combinedData = data.correctedComposition.composition.map((correctedItem) => {
        const originalItem = data.originalComposition.composition.find(
          (item) => item.component === correctedItem.component
        );

        const calculateDelta = (corrected, uncorrected) => {
          if (corrected !== undefined && uncorrected !== undefined && uncorrected != 0) {
            const delta = (((corrected - uncorrected) / uncorrected) * 100).toFixed(1);
            return delta + " %";
          }
          return "-";
        };

        const formatValue = (value) => {
          if (value === undefined) return "-";
          const numValue = parseFloat(value);
          if (numValue === 100) return "100.000";
          return numValue.toFixed(3);
        };

        const flashedGasCorrected = this.isCheckedPercentage
          ? formatValue(correctedItem.stockTankGas_MoleFraction)
          : formatValue(correctedItem.stockTankGas_MassFraction);
        const flashedGasUncorrected = this.isCheckedPercentage
          ? formatValue(originalItem?.stockTankGas_MoleFraction)
          : formatValue(originalItem?.stockTankGas_MassFraction);
        const flashedGasDelta = calculateDelta(flashedGasCorrected, flashedGasUncorrected);

        const flashedLiquidCorrected = this.isCheckedPercentage
          ? formatValue(correctedItem.stockTankOil_MoleFraction)
          : formatValue(correctedItem.stockTankOil_MassFraction);
        const flashedLiquidUncorrected = this.isCheckedPercentage
          ? formatValue(originalItem?.stockTankOil_MoleFraction)
          : formatValue(originalItem?.stockTankOil_MassFraction);
        const flashedLiquidDelta = calculateDelta(flashedLiquidCorrected, flashedLiquidUncorrected);

        const overallCorrected = this.isCheckedPercentage
          ? formatValue(correctedItem.reservoirLiquid_MoleFraction)
          : formatValue(correctedItem.reservoirLiquid_MassFraction);
        const overallUncorrected = this.isCheckedPercentage
          ? formatValue(originalItem?.reservoirLiquid_MoleFraction)
          : formatValue(originalItem?.reservoirLiquid_MassFraction);
        const overallDelta = calculateDelta(overallCorrected, overallUncorrected);

        return {
          component: correctedItem.component || "-",
          mw: correctedItem.mw !== undefined ? correctedItem.mw.toFixed(1) : "-",
          density: correctedItem.density !== undefined ? correctedItem.density.toFixed(3) : "-",
          flashedGas: {
            corrected: flashedGasCorrected,
            uncorrected: flashedGasUncorrected,
            delta: flashedGasDelta,
          },
          flashedLiquid: {
            corrected: flashedLiquidCorrected,
            uncorrected: flashedLiquidUncorrected,
            delta: flashedLiquidDelta,
          },
          overall: {
            corrected: overallCorrected,
            uncorrected: overallUncorrected,
            delta: overallDelta,
          },
        };
      });

      this.tableData = combinedData;
      this.tableData.push(this.calculateTotals(combinedData)); // Add totals row
    },

    calculateTotals(data) {
      const sum = (key, field) => {
        const total = data.reduce((acc, item) => {
          const value = parseFloat(item[key][field]) || 0;
          return acc + value;
        }, 0);

        if (total === 100) {
          return "100.000";
        }
        return total.toFixed(3);
      };

      return {
        component: "Total",
        mw: "-",
        density: "-",
        flashedGas: {
          corrected: sum("flashedGas", "corrected"),
          uncorrected: sum("flashedGas", "uncorrected"),
          delta: "-"
        },
        flashedLiquid: {
          corrected: sum("flashedLiquid", "corrected"),
          uncorrected: sum("flashedLiquid", "uncorrected"),
          delta: "-"
        },
        overall: {
          corrected: sum("overall", "corrected"),
          uncorrected: sum("overall", "uncorrected"),
          delta: "-"
        },
      };
    },
    getCellContent(component,fractionData) {
      let arrowClass = "";
      let arrowIcon = "";
      
      if (component !== "Total") {
        if (fractionData.corrected > fractionData.uncorrected) {
          arrowClass = "arrow-green";
          arrowIcon = '<i class="fa-solid fa-arrow-up"></i>';
        } else if (fractionData.corrected < fractionData.uncorrected) {
          arrowClass = "arrow-red";
          arrowIcon = '<i class="fa-solid fa-arrow-down"></i>';
        }
      }

      let finalData = fractionData.corrected;
      if (this.isChecked) {
        finalData = fractionData.delta;
      }
      return `&nbsp; <span class="${arrowClass}">${finalData} ${arrowIcon}</span>`;
    }

  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
