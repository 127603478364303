<template>
  <div>
    <RoleSuccessModalComponent
      ref="successModalRole"
      successMessage="Role Added Successfully"
    />

    <RoleErrorModalComponent
      ref="errorModalRole"
      errorMessage="Failed to add new Role"
    />

    <div class="add-sec">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <h1>Add Role</h1>
          </div>
          <div class="col-12 col-md-9">
            <form>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="role-title">Role Title</label>
                  <input
                    v-model="role_title"
                    type="text"
                    class="form-control"
                    placeholder="Enter Role"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0 pl-md-0">
                  <label for="permissions">Permissions</label>
                  <vue3-select
                    v-model="select_permission"
                    :options="permissions"
                    multiple
                    placeholder="Select Permissions"
                  />
                </div>
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-end">
              <button
                type="button"
                class="btn main-btn"
                :disabled="isLoading || !role_title || !select_permission.length"
                @click="addRole"
              >
                <span v-if="isLoading">Adding...</span>
                <div v-else>
                  <img
                    src="../../assets/images/userRoles/add-circle.png"
                    alt="Add Role"
                  />
                  <span>Add Role</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RoleSuccessModalComponent from "./RoleSuccessModalComponent.vue";
import RoleErrorModalComponent from "./RoleErrorModalComponent.vue"

export default {
  name: "AddRoleComponent",
  components: { RoleSuccessModalComponent, RoleErrorModalComponent },
  props: {
    permissions: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      role_title: null,
      select_permission: [],
      isLoading: false,
      alert: {
        show: false,
        message: '',
        type: 'success'
      }
    };
  },
  methods: {
    showAlert(message, type = 'success', duration = 3000) {
      this.alert = {
        show: true,
        message,
        type
      };
      setTimeout(() => {
        this.alert.show = false;
      }, duration);
    },
    async addRole() {
      if (!this.role_title || !this.select_permission.length) {
        return;
      }

      this.isLoading = true;

      const payload = {
        Data: {
          Role: {
            name: this.role_title,
            description: "First Description",
            permissions: this.select_permission,
          },
        },
      };

      try {
        const response = await axios.post("/roles", payload);
        
        if (response.data.statusCode == 200) {

          // Show success modal

          // Reset the form after successful creation
          this.role_title = null;
          this.select_permission = [];

          // Emit event to parent component
          this.$emit('roleAdded', response.data);
          setTimeout(() => {
            this.$refs.successModalRole.openModal();
          }, 1000);
        }

        else {
          const message = response.data.message;
          this.$refs.errorModalRole.openModal(message);
        }

      } catch (error) {
        console.error("Error creating role:", error);
        this.showAlert(
          error.response?.data?.message || 'Failed to create role',
          'error'
        );
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style scoped>
/* Add any required styling here */
</style>
