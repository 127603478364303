<template>
    <div>
      <div ref="plotContainer" style="height: 480px;"></div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  import Plotly from 'plotly.js-dist';
  import axios from 'axios';
  export default {
    name: 'ChartDataComponent',
    props: {
      filterData: Object,
      sampleData: Object,
      samples: [Object, Array],
    },
    setup(props, { emit }) {  // Use destructuring to get emit
      const plotContainer = ref(null);
  
      // Method to plot data
      const plotData = (data,correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters) => {
        if (!plotContainer.value) {
          console.error("Plot container not available.");
          return;
        }
        const traces = [];
  
        // Determine which datasets to show based on isCorrected flag
  
        const showCorrected = props.filterData.isCorrected === true || props.filterData.isCorrected === 'both';
        const showUncorrected = props.filterData.isCorrected === false || props.filterData.isCorrected === 'both';

         // Add uncorrected fluids if allowed
         if (showUncorrected && uncorrectedFluids.length) {
          traces.push({
            y: uncorrectedFluids.map(d => d.c36plus_MassFraction.toFixed(3)),
            x: uncorrectedFluids.map(d => d.c36plus_Density.toFixed(3)),
            z: uncorrectedFluids.map(d => d.c36plus_MW.toFixed(1)),
            mode: 'markers',
            type: 'scatter3d',
            marker: {
              size: 8,
              color: 'lightblue',
              symbol: 'circle-open',
              opacity: 0.8
            },
            name: 'Uncorrected Fluids',
            hovertemplate: 
              'C36+ Density: %{x}<br>' +
              'C36+ wt%: %{y}<br>' +
              'C36+ MW: %{z}<br>' +
              'Asset: %{text[0]}<br>' +   // Access Asset
              'Field: %{text[1]}<br>' +   // Access Field
              'Well: %{text[2]}<br>' +    // Access Well
              'Reservoir: %{text[3]}<br>' +
              '<extra></extra>',          // This removes the trace name in the hover box
              text: data.fluids.map(d => [d.asset, d.field, d.well, d.reservoir]) 
          });
        }
  
        // Add corrected fluids if allowed
        if (showCorrected && correctedFluids.length) {
          traces.push({
            y: correctedFluids.map(d => d.c36plus_MassFraction),
            x: correctedFluids.map(d => d.c36plus_Density),
            z: correctedFluids.map(d => d.c36plus_MW),
            mode: 'markers',
            type: 'scatter3d',
            marker: {
              size: 8,
              color: 'darkblue',
              symbol: 'circle-open',
              opacity: 0.8
            },
            name: 'Corrected Fluids',
            hovertemplate: 
              'C36+ Density: %{x}<br>' +
              'C36+ wt%: %{y}<br>' +
              'C36+ MW: %{z}<br>' +
             'Asset: %{text[0]}<br>' +   // Access Asset
              'Field: %{text[1]}<br>' +   // Access Field
              'Well: %{text[2]}<br>' +    // Access Well
              'Reservoir: %{text[3]}<br>' +
              '<extra></extra>',          // This removes the trace name in the hover box
              text: data.fluids.map(d => [d.asset, d.field, d.well, d.reservoir]) 
          });
        }
  
        // Add uncorrected cluster centers if allowed
        if (showUncorrected && uncorrectedClusterCenters.length) {
          traces.push({
            y: uncorrectedClusterCenters.map(d => d.c36plus_MassFraction.toFixed(3)),
            x: uncorrectedClusterCenters.map(d => d.c36plus_Density.toFixed(3)),
            z: uncorrectedClusterCenters.map(d => d.c36plus_MW.toFixed(1)),
            mode: 'markers',
            type: 'scatter3d',
            marker: {
              size: 7,
              color: 'lightgrey',
              symbol: 'circle-open',
              opacity: 1
            },
            name: 'Uncorrected Anchors                                            ',
            hovertemplate: 
              'C36+ Density: %{x}<br>' +
              'C36+ wt%: %{y}<br>' +
              'C36+ MW: %{z}<br>' +
              'Asset: %{text[0]}<br>' +   // Access Asset
              'Field: %{text[1]}<br>' +   // Access Field
              'Well: %{text[2]}<br>' +    // Access Well
              'Reservoir: %{text[3]}<br>' +
              '<extra></extra>',          // This removes the trace name in the hover box
              text: data.clusterCenters.map(d => [d.asset, d.field, d.well, d.reservoir]) 
          });
        }
        // Add corrected cluster centers if allowed
        if (showCorrected && correctedClusterCenters.length) {
          traces.push({
            y: correctedClusterCenters.map(d => d.c36plus_MassFraction.toFixed(3)),
            x: correctedClusterCenters.map(d => d.c36plus_Density.toFixed(3)),
            z: correctedClusterCenters.map(d => d.c36plus_MW.toFixed(1)),
            mode: 'markers',
            type: 'scatter3d',
            marker: {
              size: 7,
              color: 'lightgreen',
              symbol: 'circle-open',
              opacity: 1
            },
            name: 'Corrected Anchors',
            hovertemplate: 
              'C36+ Density: %{x}<br>' +
              'C36+ wt%: %{y}<br>' +
              'C36+ MW: %{z}<br>' +
              'Asset: %{text[0]}<br>' +   // Access Asset
              'Field: %{text[1]}<br>' +   // Access Field
              'Well: %{text[2]}<br>' +    // Access Well
              'Reservoir: %{text[3]}<br>' +
              '<extra></extra>',          // This removes the trace name in the hover box
              text: data.clusterCenters.map(d => [d.asset, d.field, d.well, d.reservoir]) 
          });
        }
        // Adding sampleData with star symbol
          if (props.sampleData && showCorrected) {
            const colorMap = {
              green: 'green',
              yellow: 'yellow',
              red: 'red',
              orange:'orange'
            };

            traces.push({
              y: [props.sampleData.correctedComposition?.c36plus_MassFraction],
              x: [props.sampleData.correctedComposition?.c36plus_Density],
              z: [props.sampleData.correctedComposition?.c36plus_MW],
              mode: 'markers',
              type: 'scatter3d',
              marker: {
                size: 6,
                color: colorMap[props.sampleData.similarityStatus] || 'gray', // Default to gray if no match
                symbol: 'x',  
                opacity: 1
              },
              name: 'This Sample                                                 ',
              hovertemplate: 
                'C36+ Density: %{x}<br>' +
                'C36+ wt%: %{y}<br>' +
                'C36+ MW: %{z}<br>' +
                `Asset: ${ props.sampleData?.location?.asset}<br>` +   // Access Asset
                `Field: ${ props.sampleData?.location?.field}<br>` +   // Access Field
                `Well: ${ props.sampleData?.location?.well}<br>` +    // Access Well
                `Reservoir: ${ props.sampleData?.location?.reservoir}<br>`+
                '<extra></extra>',          
                field: props.sampleData?.location?.field,
                well: props.sampleData?.location?.well,
                reservoir: props.sampleData?.location?.reservoir,
                legendrank: 0,
            });
          }

          if (props.sampleData && showUncorrected) {
            const colorMap = {
              green: 'green',
              yellow: 'yellow',
              red: 'red'
            };

            traces.push({
              y: [props.sampleData.originalComposition?.c36plus_MassFraction],
              x: [props.sampleData.originalComposition?.c36plus_Density],
              z: [props.sampleData.originalComposition?.c36plus_MW],
              mode: 'markers',
              type: 'scatter3d',
              marker: {
                size: 6,
                color: colorMap[props.sampleData.similarityStatus] || 'gray', // Default to gray if no match
                symbol: 'x',
                opacity: 1
              },
              name: 'This Sample',
              hovertemplate: 
                'C36+ Density: %{x}<br>' +
                'C36+ wt%: %{y}<br>' +
                'C36+ MW: %{z}<br>' +
                `Asset: ${ props.sampleData?.location?.asset}<br>` +   // Access Asset
                `Field: ${ props.sampleData?.location?.field}<br>` +   // Access Field
                `Well: ${ props.sampleData?.location?.well}<br>` +    // Access Well
                `Reservoir: ${ props.sampleData?.location?.reservoir}<br>`+
                '<extra></extra>',          
                legendrank: 0,
            });
          }
          console.log(props.samples);
          if(props.samples && props.samples.length>0) {
            // Create a single trace for all samples
            traces.push({
              y: props.samples.map(sample => sample.originalComposition?.c36plus_MassFraction),
              x: props.samples.map(sample => sample.originalComposition?.c36plus_Density),
              z: props.samples.map(sample => sample.originalComposition?.c36plus_MW),
              mode: 'markers',
              type: 'scatter3d',
              marker: {
                size: 6,
                color: props.samples.map(sample => {
                  const colorMap = {
                    green: 'green',
                    yellow: 'yellow',
                    red: 'red'
                  };
                  return colorMap[sample.similarityStatus] || 'gray';
                }),
                symbol: 'x',
                opacity: 1
              },
              name: 'Samples',
              hovertemplate: 
                'C36+ Density: %{x}<br>' +
                'C36+ wt%: %{y}<br>' +
                'C36+ MW: %{z}<br>' +
                'Asset: %{text[0]}<br>' +
                'Field: %{text[1]}<br>' +
                'Well: %{text[2]}<br>' +
                'Reservoir: %{text[3]}<br>' +
                '<extra></extra>',
              text: props.samples.map(sample => [
                sample?.location?.asset,
                sample?.location?.field,
                sample?.location?.well,
                sample?.location?.reservoir
              ]),
              legendrank: 0,
            });
          }
  
        const layout = {
          scene: {
            xaxis: { title: 'C36+ Density', range: [0.85,1.3] },
            yaxis: { title: 'C36+ wt%', range: [0,30] },
            zaxis: { title: 'C36+ MW', range: [380,1200] },
            // bgcolor: 'rgba(240, 240, 240, 1)'
          },
          // paper_bgcolor: 'lightgray',
          margin: { l: 0, r: 0, b: 0, t: 0 },
          showLegend: true,
          legend: {
            orientation: "v",
            y: 0.9,
            x: 1
          }
        };
  
        const config = {
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d', "resetCameraLastSave3d",  "orbitrotation", "pan", "tableRotation"]
        };
  
        // Safeguard against any potential rendering issues
        try {
          Plotly.newPlot(plotContainer.value, traces, layout, config);
        } catch (err) {
          console.error("Error rendering plot:", err);
        }
      };
  
      // Fetch data from API
      const fetchData = async () => {
        emit('start-loading');
        try {
          const response = await axios.get(`samples/cluster-data`, {
            params: {
              asset: props.filterData.asset.length > 0 ? JSON.stringify(props.filterData.asset) : '',
              field: props.filterData.field.length > 0 ? JSON.stringify(props.filterData.field) : '',
              well: props.filterData.well.length > 0 ? JSON.stringify(props.filterData.well) : '',
              reservoir: props.filterData.reservoir.length > 0 ? JSON.stringify(props.filterData.reservoir) : '',
              sampleType: props.filterData.sampleType.length > 0 ? JSON.stringify(props.filterData.sampleType) : '',
              fluidType: props.filterData.fluidType.length > 0 ? JSON.stringify(props.filterData.fluidType) : '',
            }
          });
  
          const data = response.data.data;
  
          const correctedFluids = [];
          const uncorrectedFluids = [];
          const correctedClusterCenters = [];
          const uncorrectedClusterCenters = [];
  
          // Separate data based on isAnchor and corrected/uncorrected
          if (data.fluids) {
            data.fluids.forEach(fluid => {
              if (fluid.corrected && typeof fluid.corrected === 'object') {
                correctedFluids.push(fluid.corrected);
              }
              if (fluid.uncorrected && typeof fluid.uncorrected === 'object') {
                uncorrectedFluids.push(fluid.uncorrected);
              }
            });
          }
  
  
          if (data.clusterCenters) {
            data.clusterCenters.forEach(cluster => {
              if (cluster.corrected && typeof cluster.corrected === 'object') {
                correctedClusterCenters.push(cluster.corrected);
              }
              if (cluster.uncorrected && typeof cluster.uncorrected === 'object') {
                uncorrectedClusterCenters.push(cluster.uncorrected);
              }
            });
          }
  
          plotData(data,correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        finally {
        emit('stop-loading'); // Stop loading after data is plotted
      }
      };
  
      // Watch for changes in props and recall API
      watch(
        [
          () => props.filterData,
          () => props.sampleData,
          () => props.samples
        ],
        async (newValues, oldValues) => {
          emit('start-loading');
          await fetchData();
          emit('stop-loading');
        },
        { deep: true }
      );
  
      // Initial fetch and plot on mount
      onMounted(() => {
        fetchData();
      });
  
      return { plotContainer };
    }
  }
  </script>
  