<template>
  <div class="main-page">
    <div class="navbar-wrapper">
      <NavbarComponent />
    </div>
    <div class="container-fluid main-wrapper">
      <div class="row no-gutters">
        <div class="col-12">
          <DashboardLeftTopComponent @update-filter="handleFilterUpdate"/>
        </div>
        <div class="col-12 col-lg-8 col-xl-8 dashboard-left-side-wrap">
          <DashboardTreeMapComponent :filter-data="filterData" :options="this.options"/>
        </div>
        <div class="col-12 col-lg-4 col-xl-4 mt-3">
          <DashboardInventoryAnalysisComponent :chartProperties="this.optionsOneD" :filter-data="filterData" />
          <DashboardRightChartsBotoomComponent :options="this.options" :filter-data="filterData" />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import FooterComponent from "@/components/Layout/FooterComponent.vue";
import DashboardLeftTopComponent from "@/components/Dashboard/DashboardLeft/DashboardLeftTopComponent.vue";
import DashboardTreeMapComponent from "@/components/Dashboard/DashboardLeft/DashboardTreeMapComponent.vue";
import DashboardInventoryAnalysisComponent from "@/components/Dashboard/DashboardRight/DashboardInventoryAnalysisComponent.vue";
import TwoDHistogram from "@/components/Charts/2DHistogram.vue";
import DashboardRightChartsBotoomComponent from '@/components/Dashboard/DashboardRight/DashboardRightChartsBotoomComponent.vue';
import axios from "axios"
export default {
  name: "SampleSummary",
  components: {
    NavbarComponent,
    FooterComponent,
    DashboardLeftTopComponent,
    DashboardTreeMapComponent,
    DashboardInventoryAnalysisComponent,
    TwoDHistogram,
    DashboardRightChartsBotoomComponent,
  },
  data() {
    return {
      filterData: null, 
      options:[],
      optionsOneD:[]
    };
  },
  methods: {
    handleFilterUpdate(newFilterData) {
      this.filterData = newFilterData; // Update filter data
    },
    async fetchProperties() {
      try {
        const response = await axios.get("/dataapi/dashboard/properties");
        if (response.data && response.data.data) {
          this.options = response.data.data.map(prop => ({
            name: prop.propertyPath,
            value: prop.property
          }));
          this.optionsOneD = response.data.data.map(prop => ({
            name: prop.propertyPath,
            value: prop.property
          }));
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    },
  },
  async mounted() {
    await this.fetchProperties(); 
  },
};
</script>
